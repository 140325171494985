import { ArrowDown2 } from 'iconsax-react';

export default function Index({ page, currentPage, setCurrentPage, style, wrapperStyle }) {
    //page[0] : current items in one page
    //page[1] : total item
    // currentPage : currentPage
    // setCurrentPage : call back when change page
    return page[0] * currentPage < page[1] ? (
        <div className="more" style={{ ...wrapperStyle }}>
            <button
                className="btn btn--blue-light btn-more-goi-y"
                style={{
                    width: '100%',
                    marginTop: '15px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    ...style
                }}
                onClick={() => setCurrentPage(currentPage + 1)}
            >
                <span className='mr-1'>Xem thêm</span> <ArrowDown2 />
            </button>
        </div>
    ) : (
        <></>
    );
}
