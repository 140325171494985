const { createContext, useState } = require("react");
import {isMobile} from "../utilities/helper";

const ChatContext = createContext({
    isChatHidden: true,
    vendor: {},
    setIsChatHidden: (isChatHidden) => {},
    setVendor: (vendor) => {}
});

const ChatContextProvider = ({ children }) => {
    const [isChatHidden, setIsChatHidden] = useState(true);
    const [vendor, setVendor] = useState();

    return (
        <>
            <ChatContext.Provider value={{ isChatHidden, vendor, setIsChatHidden, setVendor }}>
                {children}
            </ChatContext.Provider>
        </>
    );
};

export { ChatContextProvider, ChatContext };
