/* eslint-disable @next/next/no-img-element */
import Slider from "react-slick";
import { useState, useEffect, useRef } from 'react';
import http from 'utilities/http';
import {Image as Image1} from "react-bootstrap";
import { Button, Modal } from "react-bootstrap";
import { isEmpty } from "lodash";
import {getCookie, setCookie} from "framework/libs/cookie";
import { TOKEN_CONGDONG } from "framework/store/constants";
import isArray from "lodash/isArray";

export function SSlider({list, id}) {
    const settingsBanner = {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 5000,
        adaptiveHeight: true,
        dots: true,
        // eslint-disable-next-line react/display-name
        appendDots: dots => <ul>{dots}</ul>,
    };
    return <div className="mv-slider slider-ads popup-slider" style={{ textAlign: 'center' }}>
        <Slider {...settingsBanner}>
            {
                list.map((banner, index) => {
                    return <div key={index} className="items banner-tracking" id={id + `${banner.external_link}`}>
                        <a href={banner.external_link}>
                            <img src={banner.file} alt="" style={{ width: '100%', height: 'auto' }} />
                        </a>
                    </div>;
                })
            }
        </Slider>
        <div className="slick-dot">
            <div className="wrap slick-slider-dots"></div>
        </div>
    </div>;
};

export default function slider({ positionDesktop = 0, pageDesktop = 0, positionMobile = 0, pageMobile = 0, isPopup = false, isFix = false, className }) {
    const [topBanner, setTopBanner] = useState({});
    const [topBannerMobile, setTopBannerMobile] = useState({});
    const [isShow, setIsShow] = useState(false);
    const [isShowFixedBanner, setIsShowFixedBanner] = useState(true);
    const pcOnly = useRef(null);
    const spOnly = useRef(null);

    useEffect(() => {
        const fetchBanner = async () => {
            if (pcOnly.current?.getBoundingClientRect()?.width > 0 && positionDesktop > 0 && pageDesktop > 0) {
                const res1 = await http(`banner/serve?position=${positionDesktop}&page=${pageDesktop}`, {}, {}, 'v2');
                if (res1) {
                    setTopBanner(res1.data);
                }
            }
            const token = getCookie(TOKEN_CONGDONG);
            const key = `${!isEmpty(token) ? token : ''}-${positionMobile}-${pageMobile}-pop-up`;
            const cookie = getCookie(key);
            if (spOnly.current?.getBoundingClientRect()?.width > 0 && positionMobile > 0 && pageMobile > 0 &&
                !(isPopup && cookie === true)
            ) {
                const res2 = await http(`banner/serve?position=${positionMobile}&page=${pageMobile}`, {}, {}, 'v2');
                if (res2) {
                    setTopBannerMobile(res2.data);
                    if (isPopup) {
                        setTimeout(() => {
                            setIsShow(true);
                            setCookie(key, true, 1 / 24);
                        }, 30000);
                    }
                }
            }
        };
        fetchBanner();
    }, []);

    const iframeStyle = {
        'border': 0,
        'background-color': '#FFFFFF',
        'overflow': 'hidden',
        'margin': '0 auto'
    };
    const bannerWrapperStyle = {
        'position': 'relative',
        'text-align': 'center'
    };
    const bannerTargetStyle = {
        'position': 'absolute',
        'top': 0,
        'left': 0,
        'width': '100%',
        'height': '100%'
    };
    const html = topBanner?.html;
    const htmlMobile = topBannerMobile?.html;
    const divRef = useRef(null);
    const divRefMobile = useRef(null);

    useEffect(() => {
        if (!html) return;

        const slotHtml = document.createRange().createContextualFragment(html);
        divRef.current.innerHTML = '';
        divRef.current.appendChild(slotHtml);
    }, [html]);

    useEffect(() => {
        if (!htmlMobile) return;

        const slotHtml = document.createRange().createContextualFragment(htmlMobile);
        divRefMobile.current.innerHTML = '';
        divRefMobile.current.appendChild(slotHtml);
    }, [htmlMobile]);

    const idDesltopWithLink = `desktop-pos-${positionDesktop}-page-${pageDesktop}-link-${topBanner?.external_link}`;
    const idMobileWithLink = `mobile-pos-${positionMobile}-page-${pageMobile}-link-${topBannerMobile?.external_link}`;

    return <>
        <div className="sp-only" ref={spOnly}></div>
        {(!isPopup && !isFix) && <>
            <div className={"pc-only " + className} ref={pcOnly} style={{ 'padding': ( isArray(topBanner) || topBanner?.html || topBanner?.html_banner || topBanner?.file) ? '10px 0' : '0 0', textAlign: 'center' }}>
                {
                    isArray(topBanner) && <SSlider id={idDesltopWithLink} list={topBanner}/>
                }
                {topBanner?.html &&
                    <div className="banner-tracking" id={idDesltopWithLink}>
                        <div ref={divRef}></div>
                        <div style={{ clear: 'both' }}></div>
                    </div>
                }
                {!topBanner?.html && topBanner?.html_banner &&
                    <div className="banner-tracking" id={idDesltopWithLink} style={bannerWrapperStyle}>
                        <iframe src={topBanner?.html_banner}
                            width={topBanner?.width} height={topBanner?.height} frameBorder={0} scrolling="no" style={iframeStyle}>
                        </iframe>
                        <a href={topBanner?.external_link} style={bannerTargetStyle} target="_blank" rel="noreferrer">&nbsp;</a>
                    </div>
                }
                {!topBanner?.html && !topBanner?.html_banner && topBanner?.file &&
                    <div className="banner-tracking" id={idDesltopWithLink}>
                        <a href={topBanner?.external_link} target="_blank" rel="noreferrer"><Image1 layout={"fill"} src={topBanner?.file} align="" alt="" /></a>
                    </div>
                }
            </div>
            <div className="sp-only" ref={spOnly} style={{ 'padding': ( isArray(topBannerMobile) || topBannerMobile?.html || topBannerMobile?.html_banner || topBannerMobile?.file) ? '10px 0' : '0 0', textAlign: 'center' }}>
                {
                    isArray(topBannerMobile) && <SSlider id={idMobileWithLink} list={topBannerMobile}/>
                }
                {topBannerMobile?.html &&
                    <div className="banner-tracking" id={idMobileWithLink} ref={divRefMobile}></div>
                }
                {!topBannerMobile?.html && topBannerMobile?.html_banner &&
                    <div className="banner-tracking" id={idMobileWithLink} style={bannerWrapperStyle}>
                        <iframe src={topBannerMobile?.html_banner}
                            width={topBannerMobile?.width} height={topBannerMobile?.height} frameBorder={0} scrolling="no" style={iframeStyle}>
                        </iframe>
                        <a href={topBannerMobile?.external_link} style={bannerTargetStyle} target="_blank" rel="noreferrer">&nbsp;</a>
                    </div>
                }
                {!topBannerMobile?.html && !topBannerMobile?.html_banner && topBannerMobile?.file &&
                    <div className="banner-tracking" id={idMobileWithLink}>
                        <a href={topBannerMobile?.external_link} target="_blank" rel="noreferrer"><Image1 layout={"fill"} src={topBannerMobile?.file} align="" alt="" /></a>
                    </div>
                }
            </div>
        </>
        }

        {
            isPopup && <>
                <div className="sp-only" ref={spOnly}></div>
                <Modal show={isShow && (topBannerMobile?.html || topBannerMobile?.html_banner || topBannerMobile?.file) } size="sm" onHide={() => setIsShow(false)} role="document" className="modalCenter modal-popup banner-tracking" id={idMobileWithLink}>
                    <Modal.Header/>
                    <Modal.Body>
                        <div className="sp-only p-0" style={{ textAlign: 'center' }}>
                            <button type="button" className="close close-position">
                                <span onClick={() => setIsShow(false)}>
                                    <img src="/images/Close-circle.svg" width={24}/>
                                </span>
                            </button>
                            {topBannerMobile?.html &&
                                <div ref={divRefMobile} className="banner-tracking" id={idMobileWithLink}></div>
                            }
                            {!topBannerMobile?.html && topBannerMobile?.html_banner &&
                                <div style={bannerWrapperStyle} className="banner-tracking" id={idMobileWithLink}>
                                    <iframe src={topBannerMobile?.html_banner}
                                        width={topBannerMobile?.width} height={topBannerMobile?.height} frameBorder={0} scrolling="no" style={iframeStyle}>
                                    </iframe>
                                    <a href={topBannerMobile?.external_link} style={bannerTargetStyle} target="_blank" rel="noreferrer">&nbsp;</a>
                                </div>
                            }
                            {!topBannerMobile?.html && !topBannerMobile?.html_banner && topBannerMobile?.file &&
                                <div className="banner-tracking" id={idMobileWithLink}>
                                    <a href={topBannerMobile?.external_link} target="_blank" rel="noreferrer">
                                        <Image1 layout={"fill"} className="image-popup" src={topBannerMobile?.file} align="" alt="" />
                                    </a>
                                </div>
                            }
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        }

        {
            (isFix && isShowFixedBanner && (topBannerMobile?.html || topBannerMobile?.html_banner || topBannerMobile?.file ) ) && <>
                <div className="sp-only fixed-banner banner-tracking" id={idMobileWithLink} ref={spOnly} style={{ 'padding': ( isArray(topBannerMobile) || topBannerMobile?.html || topBannerMobile?.html_banner || topBannerMobile?.file) ? '10px 0' : '0 0', textAlign: 'center' }}>
                    {
                        isArray(topBannerMobile) && <SSlider list={topBannerMobile}/>
                    }
                    {topBannerMobile?.html &&
                        <div ref={divRefMobile}></div>
                    }
                    {!topBannerMobile?.html && topBannerMobile?.html_banner &&
                        <div style={bannerWrapperStyle}>
                            <iframe src={topBannerMobile?.html_banner}
                                width={topBannerMobile?.width} height={topBannerMobile?.height} frameBorder={0} scrolling="no" style={iframeStyle}>
                            </iframe>
                            <a href={topBannerMobile?.external_link} style={bannerTargetStyle} target="_blank" rel="noreferrer">&nbsp;</a>
                        </div>
                    }
                    {!topBannerMobile?.html && !topBannerMobile?.html_banner && topBannerMobile?.file &&
                        <a href={topBannerMobile?.external_link} target="_blank" rel="noreferrer"><Image1 layout={"fill"} src={topBannerMobile?.file} align="" alt="" /></a>
                    }
                    {<button type="button" className="close close-position">
                        <span onClick={() => setIsShowFixedBanner(false)}>
                            <img src="/images/Close-circle.svg" width={24} />
                        </span>
                    </button>}
                </div>
            </>
        }
    </>;
}