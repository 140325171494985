import SyncLoader from "react-spinners/SyncLoader";
export function Loader({ loading }) {
    const cssLoader = `
        display: flex;
        justify-content: center;
        position: fixed;
        height: 100%;
        width: 100%;
        top:0px;
        left: 0px;
        align-items: center;
        z-index: 1000001;
    `;
    return <>
        <div className={loading ? "d-block" : "d-none"} style={{ zIndex: "1000000", position: "fixed", top: "0px", left: "0px", background: "gray", width: "100%", height: "100%", opacity: "0.5" }}></div>
        <SyncLoader css={cssLoader} color={"#34BEEF"} loading={loading} size={15} />
    </>;

}