import http from 'utilities/http';
import { isEmpty } from 'lodash';
import { API_URL, IMG_URL } from './constants';

export const isStaging = API_URL === 'https://staging-sellercenter.happynest.vn';
export const getShopCategories = async () => {
    let categories = [];

    const res = await http('/graphql', {}, {
        token: 'default',
        method: 'POST',
        body: {
            query: `
                {
                    categoryList {
                        id
                        level
                        name
                        path
                        url_path
                        url_key
                        position
                        image
                        children {
                            id
                            level
                            name
                            path
                            url_path
                            url_key
                            position
                            image
                          children {
                                id
                                level
                                name
                                path
                                url_path
                                url_key
                                position
                                image
                            children {
                                    id
                                    level
                                    name
                                    path
                                    url_path
                                    url_key
                                    position
                                    image
                                children {
                                        id
                                        level
                                        name
                                        path
                                        url_path
                                        url_key
                                        position
                                        image
                                    }
                                }
                            }
                        }
                    }
                }
                `
        }
    });

    if (isEmpty(res?.data?.categoryList) || !res.data.categoryList[0]?.children) return;
    categories = res.data.categoryList[0]?.children || [];
    categories.sort((a, b) => a.position > b.position ? 1 : -1);

    return categories;
}