/* eslint-disable @next/next/no-img-element */
import Image from "next/image";
import dayjs from "dayjs";

const stupidLogo = 'https://sellercenter.happynest.vn/static/webapi_rest/_view/vi_VN/Vnecoms_Vendors/images/no-logo.jpg';
const ChatList = ({ chats, activeChat, openChat }) => {
    let chatItems = [];
    chats.forEach((chat, index) => {
        chat.vendor_logo_url = chat.vendor_logo_url != stupidLogo ? chat.vendor_logo_url : 'https://happynest.vn/home2017-assets/avatar-happynest@2x.png';
        const chatItem = (
            <li
                className={(activeChat.isPro == chat.isPro && activeChat.id == chat.vendor_id) ? "active" : ""}
                key={'' + index + chat.vendor_id}
                onClick={() => openChat(chat)}
            >
                <div className="chart-thumb wrap-next-image">
                    <img src={chat.vendor_logo_url}></img>
                    <Image layout='fill' src={chat.vendor_logo_url || '/images/icon-app.svg'} alt="logo" />
                </div>
                <div className="chart-info">
                    <div className="btnChat-number">
                        {chat.unread > 0 && <span>{chat.unread}</span>}
                    </div>
                    <p className='chart-name'>
                        {chat.vendor_name}
                        {/* <span className="chartOn"></span> */}
                    </p>
                    <div className={chat.unread > 0 ? "chart-text font-weight-bold" : 'chart-text'}>
                        {/* Xin chào, cảm ơn bạn đã liên... */}
                        {!(chat.last_message.startsWith('<div') || chat.last_message.startsWith('[img'))
                            ? chat.last_message
                            : 'Đã gửi ảnh'
                        }
                    </div>
                    <p className="chart-date">{dayjs(chat.last_message_date).format('DD/MM/YYYY HH:mm:ss')}</p>
                    {/* <div className="chart-more">
                            <div className="dropdown">
                                <button className="btn" data-toggle="dropdown">
                                    <Image
                                        src="/images/icon-moreChart.svg"
                                        width={20}
                                        height={20}
                                        alt=""
                                    />
                                </button>
                                <div className="dropdown-menu dropdown-menu-right dropdown-menu-more">
                                    <ul>
                                        <li>
                                            <div className="more-icon">
                                                <Image
                                                    src="/images/icon-tick.svg"
                                                    width={20}
                                                    height={20}
                                                    alt=""
                                                />
                                            </div>
                                            <div className="more-text">
                                                <p className="tlt">
                                                    Đánh dấu đã đọc
                                                </p>
                                                <p className="text">
                                                    Đánh dấu tin nhắn đã đọc
                                                </p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="more-icon">
                                                <Image
                                                    src="/images/icon-delete.svg"
                                                    width={20}
                                                    height={20}
                                                    alt=""
                                                />
                                            </div>
                                            <div className="more-text">
                                                <p className="tlt">
                                                    Xóa tin nhắn
                                                </p>
                                                <p className="text">
                                                    Xóa hoàn toàn và không thể
                                                    khôi phục
                                                </p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div> */}
                </div>
            </li>
        );
        chatItems.push(chatItem);
    });

    return (
        <div className="sideBar">
            <ul>
                {chatItems}
            </ul>
        </div>
    );
};

export default ChatList;
