import _ from "lodash";

export class BaseSchema {
    schema = [];

    constructor() {
        this.schema = _.concat(this.schema, this.organization(), this.localBusiness(), this.webSite());
    }

    init() {
        return this.schema;
    }

    webSite() {
        return {
            "@context": "https://schema.org/", "@type": "WebSite", "url": "https://happynest.vn/", "potentialAction": {
                "@type": "SearchAction",
                "target": {"@type": "EntryPoint", "urlTemplate": "https://happynest.vn/tim-kiem?keyword={keyword}"},
                "query-input": {
                    "@type": "PropertyValueSpecification",
                    "valueRequired": "http://schema.org/True",
                    "valueName": "keyword"
                }
            }
        };
    }

    localBusiness() {
        return {
            "@context": "https://schema.org/",
            "@type": "LocalBusiness",
            "name": "HAPPYNEST.VN",
            "email": "info@happynest.vn",
            "url": "https://happynest.vn/",
            "currenciesAccepted": ["VND", "USD", "EUR"],
            "openingHours": ["Mo-Fri 8:00-17:30", "Sa 8:00-12:00"],
            "telephone": ["0934680636", "02422806688"],
            "logo": "https://happynest.vn/v2019/img/logo-seo-happynest.png",
            "image": "https://happynest.vn/v2019/img/logo-seo-happynest.png",
            "priceRange": "$$$",
            "address": {
                "@type": "PostalAddress",
                "streetAddress": "Số 97 Hoàng Ngân, phường Nhân Chính, quận Thanh Xuân, Thành phố Hà Nội",
                "addressLocality": "Hà Nội",
                "addressRegion": "HN",
                "postalCode": 115070,
                "addressCountry": {"@type": "Country", "name": "VN"}
            },
            "geo": {"@type": "GeoCoordinates", "latitude": "21.0074272", "longitude": "105.8073979"}
        };
    }

    organization() {
        return {
            "@context": "https://schema.org/",
            "@type": "Organization",
            "name": "HAPPYNEST.VN",
            "email": "info@happynest.vn",
            "url": "https://happynest.vn/",
            "telephone": ["+84934680636", "+842422806688"],
            "address": {
                "@type": "PostalAddress",
                "streetAddress": "Số 97 Hoàng Ngân, phường Nhân Chính, quận Thanh Xuân, Thành phố Hà Nội",
                "addressLocality": "Hà Nội",
                "addressRegion": "HN",
                "postalCode": 115070,
                "addressCountry": {"@type": "Country", "name": "VN"}
            },
            "logo": {
                "@type": "ImageObject",
                "thumbnailUrl": "https://happynest.vn/v2019/img/logo-seo-happynest.png",
                "url": "https://happynest.vn/v2019/img/logo-seo-happynest.png"
            },
            "image": {
                "@type": "ImageObject",
                "thumbnailUrl": "https://happynest.vn/v2019/img/logo-seo-happynest.png",
                "url": "https://happynest.vn/v2019/img/logo-seo-happynest.png"
            },
            // "founder": {
            //     "@type": "Person",
            //     "name": "Trinh Le Hung",
            //     "familyName": "Trinh",
            //     "givenName": "Le Hung",
            //     "email": "hungtl@autodaily.vn",
            //     "telephone": "+84902251368"
            // },
            "sameAs": [
                "https://www.facebook.com/happynestvn",
                "https://www.instagram.com/happynest.vn/",
                "https://www.youtube.com/channel/UCmpa_rk-zhBYJCDM_hdu2-A",
                "https://www.tiktok.com/@happynestvn?lang=en",
                "https://www.pinterest.com/happynest2016/"
            ]
        };
    }
}