import http from 'utilities/http';
import {getCookie, setCookie} from "framework/libs/cookie";
import {CART, TOKEN_SHOP} from "framework/store/constants";
import {isEmpty} from "lodash";

export const apiAddProductToCart = async (data) => {
    let response = null;
    if (!isEmpty(getCookie(TOKEN_SHOP))) {
        response = await http(`/rest/V1/carts/mine/items`, {}, {
            method: "POST",
            token: `Bearer ${getCookie(TOKEN_SHOP)}`,
            body: data
        });
    } else {
        response = await http(
            `/rest/V1/guest-carts/${getCookie(CART)}/items`,
            {},
            {
                method: 'POST',
                body: data,
                token: 'default'
            }
        );
    }
    return response;
};
export const createCart = async () => {
    let response = null;
    if (!isEmpty(getCookie(TOKEN_SHOP))) {
        response = await http(`/rest/V1/carts/mine`, {}, {
            token: `Bearer ${getCookie(TOKEN_SHOP)}`,
            method: "POST",
        });
    }
    // else if(isEmpty(getCookie(CART))){
    //     response = await http(
    //         '/rest/V1/guest-carts',
    //         {},
    //         {
    //             method: 'POST',
    //             token: 'default'
    //         }
    //     );
    //     setCookie(CART, response);
    // }
    return response;
};
export const fetchCartProduct = async () => {
    let response = null;
    if (!isEmpty(getCookie(TOKEN_SHOP))) {
        response = await http('/rest/V1/carts/mine', {}, {token: 'Bearer ' + getCookie(TOKEN_SHOP)});
    } else {
        response = await http(
            `/rest/V1/guest-carts/${getCookie(CART)}`,
            {},
            {
                token: 'default'
            }
        );
    }
    return response;
};

export const toggleItem = async (data) => {
    if (!isEmpty(getCookie(TOKEN_SHOP))) {
        await http(`/rest/V1/carts/mine/toggleItem`, {}, {
            method: 'POST',
            token: `Bearer ${getCookie(TOKEN_SHOP)}`,
            body: {
                itemIds: data.join(',')
            },
        });
    } else if (!isEmpty(getCookie(CART))) {
        await http(`/rest/V1/guest-carts/${getCookie(CART)}/toggleItem`, {}, {
            body: {
                itemIds: data.join(',')
            },
            method: 'POST'
        });
    }
};

export const removeItem = async (id) => {
    if (!isEmpty(getCookie(TOKEN_SHOP))) {
        await http(`/rest/V1/carts/mine/items/${id}`, {}, {
            method: 'DELETE',
            token: `Bearer ${getCookie(TOKEN_SHOP)}`,
        });
    } else if (!isEmpty(getCookie(CART))) {
        await http(`/rest/V1/guest-carts/${getCookie(CART)}/items/${id}`, {}, {
            method: 'DELETE'
        });
    }
};
export const updateQuantity = async (data) => {
    if (!isEmpty(getCookie(TOKEN_SHOP))) {
        await http('/rest/V1/carts/mine/items/' + parseInt(data.item_id), {}, {
            body: {
                cartItem: {
                    item_id: data.item_id,
                    quote_id: data.quote_id,
                    qty: data.qty
                }
            },
            token: `Bearer ${getCookie(TOKEN_SHOP)}`,
            method: 'PUT'
        });
    } else if (!isEmpty(getCookie(CART))) {
        await http(`/rest/V1/guest-carts/${getCookie(CART)}/items/${data.item_id}`, {}, {
            body: {
                cartItem: {
                    item_id: data.item_id,
                    quote_id: data.quote_id,
                    qty: data.qty
                }
            },
            token: `Bearer ${getCookie(TOKEN_SHOP)}`,
            method: 'PUT'
        });
    }
};
export const getCouponByVendor = async (id) => {
    const response = await http(`/rest/V1/vendors/coupon/${id}`, {
        size: 0,
    }, {
        token: 'default',
    });
    return response.items || [];
};

export const payOrder = async (method) => {
    let response = null;
    if (!isEmpty(getCookie(TOKEN_SHOP))) {
        response = await http(`/rest/V1/carts/mine/payment-information`, {}, {
            body: {
                paymentMethod: {
                    method
                }
            },
            token: `Bearer ${getCookie(TOKEN_SHOP)}`,
            method: 'POST'
        });
    } else if (!isEmpty(getCookie(CART))) {
        response = await http(`/rest/V1/guest-carts/${getCookie(CART)}/order`, {}, {
            body: {
                paymentMethod: {
                    method
                }
            },
            token: `default`,
            method: 'PUT'
        });
    }
    return response;
};
export const applyDiscountItem = async (cartId, itemId, type) => {
    let response = null;
    if (!isEmpty(getCookie(TOKEN_SHOP))) {
        const res1 = await http(`/rest/V1/cart/updateItemPrice/dealhot/${cartId}/${itemId}`, {}, {
            method: "PUT",
            token: `Bearer ${getCookie(TOKEN_SHOP)}`,
        });
        const res2 = await http(`/rest/V1/cart/updateItemPrice/flashsales/${cartId}/${itemId}`, {}, {
            method: "PUT",
            token: `Bearer ${getCookie(TOKEN_SHOP)}`,
        });
    } else {
        const res1 = await http(`/rest/V1/cart/updateItemPriceGuest/dealhot/${cartId}/${itemId}`, {}, {
            method: "PUT",
            token: `default`,
        });
        const res2 = await http(`/rest/V1/cart/updateItemPriceGuest/flashsales/${cartId}/${itemId}`, {}, {
            method: "PUT",
            token: `default`,
        });
    }
    return response;
};
export const getDiscountDetail = async () => {
    let response = null;
    if (!isEmpty(getCookie(TOKEN_SHOP))) {
        response = await http(`/rest/V1/carts/mine/vendor-coupons`, {}, {
            token: `Bearer ${getCookie(TOKEN_SHOP)}`,
        });
    }
    return response; 
};
export const applyCoupon = async (couponCode, type = 'add') => {
    let response = null;
    if (!isEmpty(getCookie(TOKEN_SHOP))) {
        response = await http(`/rest/V1/carts/mine/vendor-coupons/${couponCode}`, {}, {
            method: type == 'add' ? "PUT" : 'DELETE',
            token: `Bearer ${getCookie(TOKEN_SHOP)}`,
        });
    } else {
        const res1 = await http(`/rest/V1/cart/updateItemPriceGuest/dealhot/${cartId}/${itemId}`, {}, {
            method: "PUT",
            token: `default`,
        });
        const res2 = await http(`/rest/V1/cart/updateItemPriceGuest/flashsales/${cartId}/${itemId}`, {}, {
            method: "PUT",
            token: `default`,
        });
    }
    return response;
};
export const estimateShipping = async (id) => {
    const estShippingMethod = await http('/rest/default/V1/carts/mine/estimate-shipping-methods-by-address-id', {}, {
        token: `Bearer ${getCookie(TOKEN_SHOP)}`,
        body: {
            addressId: id
        },
        method: 'POST',
    });
    return estShippingMethod;
};
export const addShipping = async (carts, idDefaultAddress, shipInfo) => {
    const formatBodyAddress = (address) => {
        const body = {
            customerAddressId: `${address.id}`,
            countryId: address?.region?.region_code,
            regionId: `${address?.region?.region_id}`,
            region: address?.region?.region,
            customerId: `${carts?.customer.id}`,
            street: address.street,
            city: address.city,
            firstname: address.firstname,
            lastname: address.lastname,
            customAttributes: address.custom_attributes,
            extension_attributes: address.extension_attributes
        };
        return body;
    };
    const currentAddress = carts?.customer?.addresses.find(item => item.default_shipping == true);
    currentAddress.extension_attributes = {};

    if (!isEmpty(currentAddress.custom_attributes)) {
        currentAddress.extension_attributes.subdistrict_id = currentAddress.custom_attributes.find(x => x.attribute_code == 'subdistrict_id')?.value;
        currentAddress.extension_attributes.subdistrict = currentAddress.custom_attributes.find(x => x.attribute_code == 'subdistrict')?.value;
        currentAddress.extension_attributes.city_id = currentAddress.custom_attributes.find(x => x.attribute_code == 'city_id')?.value;
    }

    await http('/rest/V1/carts/mine/shipping-information', {}, {
        token: `Bearer ${getCookie(TOKEN_SHOP)}`,
        body: {
            addressInformation: {
                shipping_address: formatBodyAddress(currentAddress),
                billing_address: formatBodyAddress(currentAddress),
                shipping_method_code: shipInfo?.method_code,
                shipping_carrier_code: shipInfo?.carrier_code,
                extension_attributes: {}
            },
        },
        method: 'POST',
    });
};