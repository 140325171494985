/* eslint-disable @next/next/no-img-element */
/* eslint-disable @next/next/no-html-link-for-pages */
import Image from 'next/image';
import {useEffect, useState} from "react";
import {useCart} from "../../framework/store";
export default function FooterSiteMini() {
    const [stateStore, actions] = useCart();

    const [state, _setState] = useState({
        information: {
            name: "",
            logo: "",
        },
    });
    return <footer className="footer">
        <div className="wrap wrap-sitemini">
            <div className="logo">
                <a href="#"><Image width={63} height={30} src={stateStore.informationSite?.logo} alt={stateStore.informationSite?.name}/></a>
                <div className="footerInfo sp-only" style={{marginTop:"10px"}}>
                    <ul>
                        <li><a className="site-mini" href={"https://www.lg.com/vn/nhom-san-pham"}>LG
                            Shop</a></li>
                        <li><a className="site-mini" href={"https://www.lg.com/vn"}>LG Website</a></li>
                    </ul>
                </div>
            </div>
            <div className="footerInfo pc-only">
                <ul>
                    <li><a className="site-mini" href={"https://www.lg.com/vn/nhom-san-pham"}>LG Shop</a></li>
                    <li><a className="site-mini" href={"https://www.lg.com/vn"}>LG Website</a></li>
                </ul>
            </div>
            <div className="footerWrap">
                <div className="footerIntrol">
                    <div className="signForm pc-only">
                        <h3 className="signForm-title">Đăng ký nhận tin</h3>
                        <div className="signForm-input">
                            <input className="form-control" type="text" placeholder="Email của bạn"/>
                            <button className="btn btn--sitemini"><span>
                                <Image width={20} height={20} src="/images/icon-send.svg" alt=""/></span>Gửi
                            </button>
                        </div>
                    </div>
                </div>
                <div className="signForm sp-only mt-3" style={{width: '100%', paddingLeft: '10%'}}>
                    <h3 className="signForm-title">Đăng ký nhận tin</h3>
                    <div className="signForm-input">
                        <input className="form-control" type="text" placeholder="Email của bạn"/>
                        <button className="btn btn--sitemini"><span>
                            <Image width={20} height={20} src="/images/icon-send.svg" alt=""/></span>Gửi
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <address className="copyright">© 2021 Happynest</address>
    </footer>;
};