import Image from "next/image";

const ChatHeader = (props) => {
    return (
        <div className="chatHeader">
            <div className="chatHeader__search">
                <input
                    className="form-control"
                    type="text"
                    onChange={(e) => props.find(e.target.value)}
                    placeholder="Tìm kiếm"
                />
                <button className="btn">
                    <Image
                        src="/images/icon-searchWhite.svg"
                        width={20}
                        height={20}
                        alt=""
                    />
                </button>
            </div>
            <div className="chatHeader__close">
                <button className="btn" onClick={props.collapseChat}>
                    <Image
                        src="/images/icon-arrow-down.svg"
                        width={20}
                        height={20}
                        alt=""
                    />
                </button>
            </div>
        </div>
    );
};

export default ChatHeader;
