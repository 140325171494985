export  const selectModel = {
    value: '',
    error: '',
    message: '',
    placeholder: '',
    options: [],
    show: false,
    isRequired: false,
    isMultiple: false,
}

export const inputModel = {
    value: '',
    error: '',
    message: '',
    placeholder: '',
    show: false,
    isRequired: false,
}

// Viết 1 hàm validate định dạng email
export const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
}

// Viết 1 hàm validate định dạng số điện thoại
export const validatePhone = (phone) => {
    const re = /(84|0[3|5|7|8|9])+([0-9]{8})\b/;
    return re.test(phone);
}
