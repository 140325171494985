import dayjs from 'dayjs';

const pushEvent = (eventName, data, url) => {
    if (window.Insider) {
        url = url || window.location.href;
        window.Insider.track('events', [{
            "event_name": eventName,
            "timestamp": dayjs.unix(),
            "event_params": {
                "url": url,
                ...data,
            }
        }]);
    }
}

export const insiderDatHang = (data, url) => {
    pushEvent('test_click_form_tu_van', data, url);
}

export const insider_click_form_tu_van = (data, url) => {
    // data = {
    //     pro_id: '',
    //     pro_name: '',
    // }
    pushEvent('click_form_tu_van', data, url);
}

export const insider_click_button_tu_van = (data, url) => {
    // data = {
    //     pro_id: '',
    //     pro_name: '',
    // }
    pushEvent('click_button_tu_van', data, url);
}

export const insider_click_gui_tu_van = (data, url) => {
    // data = {
    //     pro_id: '',
    //     pro_name: '',
    //     success: '',
    // }
    pushEvent('click_gui_tu_van', data, url);
}

export const insider_view_bai_viet_chi_tiet = (data, url) => {
    // data = {
    //     title: '',
    //     category_ids: [],
    //     category_names: [],
    //     category_slugs: [],
    //     tag_ids: [],
    //     tag_names: [],
    //     tag_slugs: [],
    //     author_id: '',
    //     author_name: '',
    // }
    pushEvent('view_bai_viet_chi_tiet', data, url);
}