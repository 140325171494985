/* eslint-disable @next/next/no-img-element */
import Image from "next/image";
import { useEffect, useRef, useState } from "react";
import { API_URL, CHAT } from "utilities/constants";
import Link from "next/link";
import { toast } from "react-toastify";
import {TOKEN_CHAT} from "framework/store/constants";
import {getCookie, setCookie} from "framework/libs/cookie";
import { isEmpty } from "lodash";
import { getUrlName } from 'utilities/helper';

export function Image1({ imgUrl }) {
    return <Image
        src={imgUrl}
        layout="fill"
        objectFit='contain'
        className="custom-img"
        alt=""
    />;
}
const ChatForm = ({ vendorId, vendorName, vendorLogo, messages, sendMessage, vendor }) => {
    const [input, setInput] = useState("");
    const fileUploadRef = useRef(null);
    const scrollRef = useRef(null);

    const uploadImage = () => {
        // const { current } = fileUploadRef
        fileUploadRef.current?.click();
    };

    const handleUploadImage = async (e) => {
        e.preventDefault();
        // console.log(e.target.files);
        if (e.target.files) {
            if (!vendor.isPro) {
                const formData = new FormData();
                formData.append("param_name", "image");
                formData.append("chat_id", "136");
                formData.append("image", e.target.files[0]);
    
                const response = await fetch(API_URL + "/vlivechat/index/upload", {
                    method: "POST",
                    body: formData
                });
    
                if (response.status == 200) {
                    const imageData = await response.json();
                    if (imageData && imageData.url) {
                        const message = `<div class=\"vlivechat-img-container\"><img src=\"${imageData.url}\" /></div>`;
                        sendMessage(message);
                    }
    
                    if(imageData.error) {
                        toast.error(imageData.error);
                    }
                }
            } else {
                const formData = new FormData();
                formData.append("_token", getCookie(TOKEN_CHAT));
                formData.append("photo", e.target.files[0]);

                const response = await fetch(CHAT + "uploads", {
                    method: "POST",
                    body: formData,
                    headers: new Headers({
                        'Authorization': getCookie(TOKEN_CHAT)
                    })
                });
                if (response.status == 200) {
                    const imageData = await response.json();
                    if (imageData && imageData.image.url) {
                        // const message = `<div class=\"vlivechat-img-container\"><img src=\"${imageData.url}\" /></div>`;
                        const message = '';
                        const images = [imageData.image];
                        sendMessage(message, images);
                    }
    
                    if(imageData.error) {
                        toast.error(imageData.error || 'Đã có lỗi xảy ra, vui lòng thử lại!');
                        e.target.files = null;
                    }
                }
            }
           
        }
        e.target.files = null;
    };

    const keyPress = (e) => {
        if (e.key === "Enter") {
            (async () => {
                try {
                    submitMessage();
                } catch (error) {
                    setLoading(false);
                    // console.log(error);
                }
            })();
        }
    };

    const submitMessage = () => {
        sendMessage(input);
        setInput("");
    };

    useEffect(() => {
        if(!scrollRef?.current) return;
        scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }, [messages, scrollRef]);

    return (
        <>
            <div className="chatForm">
                {!vendorId ? (
                    <div
                        style={{
                            background: "white",
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderLeft: "1px solid #e4e6e8",
                        }}
                    >
                        <p>Xin chào!</p>
                    </div>
                ) : (
                    <>
                        <div style={{ display: "flex", padding: "16px", background: "white", fontWeight: 600 }}>
                            <Link href={`/shop/profile/${vendorId}/${getUrlName(vendorName)}`} passHref={true} prefetch={false}>
                                <>
                                    <div className="chat-form-logo wrap-next-image cursor-pointer">
                                        <Image
                                            src={vendorLogo ?? "/images/icon-app.svg"}
                                            width={40}
                                            height={40}
                                            alt=""
                                        />
                                    </div>
                                    <p className="ml-2 cursor-pointer" style={{ lineHeight: '40px' }}>
                                        {vendorName}
                                    </p>
                                </>
                            </Link>
                           
                        </div>
                        <div className="scrollBar">
                            {/* <div className="chatPro">
                            <a href="#">
                                <div className="chatPro__thumb">
                                    <Image
                                        src="/images/img-sale03.png"
                                        width={64}
                                        height={64}
                                        alt=""
                                    />
                                </div>
                                <div className="chatPro__content">
                                    <p className="tlt">Ghế Eames Thổ Cẩm</p>
                                    <p className="view">
                                        <span className="view-evaluate">
                                            <Image
                                                src="/images/icon-star.svg"
                                                width={14}
                                                height={14}
                                                alt=""
                                            />
                                            4.9
                                        </span>
                                        <span className="view-review">
                                            1.268 đánh giá
                                        </span>
                                    </p>
                                    <p className="price">
                                        <span className="price-discount">
                                            -32%
                                        </span>
                                        <span className="price-number">
                                            đ2.999.000
                                        </span>
                                    </p>
                                </div>
                            </a>
                        </div> */}
                            <div className="chartText">
                                {messages && messages.map((message, index) => {
                                    if (message.type == "1" || message.type == "out") {
                                        let content;
                                        if (message.message?.includes("<img src=\"")) {
                                            const imgUrl = message.message.split("<img src=\"").pop().split("\" />")[0];
                                            content = (
                                                <div className="chart-img unset-img">
                                                    <Image1 imgUrl={imgUrl} />
                                                </div>
                                            );
                                        } 

                                        if (!isEmpty(message.images)) {
                                            content = (message.images.map((img, index) => {
                                                return <div key={index} className="chart-img unset-img">
                                                    <Image1 imgUrl={img.url} />
                                                </div>;
                                            }));
                                        }

                                        if (!message.message?.includes("<img src=\"") && isEmpty(message.images)) {
                                            content = (<p> {message.message}</p>);
                                        }

                                        return (
                                            <div className="chartMe" key={index}>
                                                {content}
                                            </div>
                                        );
                                    } 
                                    if (message.type == "2" || message.type == "in") {
                                        let content;
                                        if (message.message?.includes("<img src=\"")) {
                                            const imgUrl = message.message.split("<img src=\"").pop().split("\" />")[0];
                                            content = (
                                                <div className="chart-img unset-img">
                                                    <Image1 imgUrl={imgUrl} />
                                                </div>
                                            );
                                        } 

                                        if (!isEmpty(message.images)) {
                                            content = (message.images.map((img, index) => {
                                                return <div key={index} className="chart-img unset-img">
                                                    <Image1 imgUrl={img.url} />
                                                </div>;
                                            }));
                                        }

                                        if (!message.message?.includes("<img src=\"") && isEmpty(message.images)) {
                                            content = (<p> {message.message}</p>);
                                        }
                                        return (
                                            <div className="chartYou" key={index}>
                                                <div className="chartYou__text">
                                                    {content}
                                                </div>
                                            </div>
                                        );
                                    }
                                })}
                            </div>
                            <div ref={scrollRef}></div>
                        </div>
                        <div className="chartInput">
                            <div className="chartImages">
                                <button className="btn" onClick={uploadImage}>
                                    <Image
                                        src="/images/icon-image.svg"
                                        width={20}
                                        height={20}
                                        alt=""
                                    />
                                </button>
                                <input
                                    type="file"
                                    id="file"
                                    style={{ display: "none" }}
                                    ref={fileUploadRef}
                                    accept="image/*"
                                    onChange={handleUploadImage}
                                />
                            </div>
                            <div className="chartImport">
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Viết tin nhắn"
                                    value={input}
                                    onChange={e => setInput(e.target.value)}
                                    onKeyDown={keyPress}
                                />
                                {/* <button className="btn">
                                <Image
                                    src="/images/icon-chart.svg"
                                    width={20}
                                    height={20}
                                    alt=""
                                />
                            </button> */}
                            </div>
                            <div className="chartSend">
                                <button className="btn" onClick={submitMessage}>
                                    <Image
                                        src="/images/icon-sendChart.svg"
                                        width={20}
                                        height={20}
                                        alt=""
                                    />
                                </button>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default ChatForm;
