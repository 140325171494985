import {createStore, createSubscriber, createHook} from 'react-sweet-state';

const Store = createStore({
    initialState: {
        cart: {},
        menus: [],
        profileCommunity: null,
        profileShop: null,
        search: '',
        vendorList: [],
        wishListAll: [],
        savedCoupon: [],
        lastRoute: '',
        categories: [],
        shopCategories: [],
        consultantFilters: {},
        currentYear: 0,
        lamNha: {},
        informationSite: {
            name: "",
            logo: "",
            slug: ""
        },
    },
    actions: {
        setProfileShop: (data) => ({setState}) => {
            setState({
                profileShop: data,
            });
        },
        setInformationSite: (data) => ({setState}) => {
            setState({
                informationSite: data,
            });
        },
        setProfileCommunity: (data) => ({setState}) => {
            setState({
                profileCommunity: data,
            });
        },
        cart: (data) => ({setState}) => {
            setState({
                cart: data,
            });
        },
        setMenu: (menu) => ({setState}) => {
            setState({
                menus: menu,
            });
        },
        setSearch: (search) => ({setState}) => {
            setState({
                search,
            });
        },
        setVendorList: (data) => ({setState}) => {
            setState({
                vendorList: data,
            });
        },
        setWishListAll: (data) => ({setState}) => {
            setState({
                wishListAll: data,
            });
        },
        setSavedCoupon: (data) => ({setState}) => {
            setState({
                savedCoupon: data,
            });
        },
        setLastRoute: (data) => ({setState}) => {
            setState({
                lastRoute: data,
            });
        },
        setCategoriesShop: (data) => ({setState}) => {
            setState({
                categories: data,
            });
        },
        set: (name, data) => ({setState}) => {
            setState({
                [name]: data,
            });
        },
    },
    name: 'store',
});

export const useCart = createHook(Store);
