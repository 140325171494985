import Select, { components } from "react-select";
import http from 'utilities/http';
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import _, { isArray, isEmpty, set } from "lodash";
import { UserOctagon, MaskRight, MainComponent, House2, Edit2, TickCircle } from 'iconsax-react';
import { useCart } from "framework/store";
import { inputModel, selectModel, validateEmail, validatePhone } from "models/inputModel";
import { insider_click_form_tu_van, insider_click_gui_tu_van } from "utilities/insider-events";
import { getCookie, setCookie } from "framework/libs/cookie";
import { Radio, Space } from "antd";
import {MyGetValueMultiSelect, MySelectInput} from "../mua-sam-hoan-thien-nha/formMuaSamHoanThienNha";

export default function FormDangKyTuVan({ style, chuyenGia, article, hideHeader = false, displayFull = false, source }) {
    chuyenGia = chuyenGia || { id: 18, display_name: 'Happynest' };
    const [store] = useCart();
    const [state, setState] = useState({
        name: { ...inputModel, isRequired: true, placeholder: 'Họ và tên' },
        email: { ...inputModel, isRequired: false, placeholder: 'Email' },
        phone: { ...inputModel, isRequired: true, placeholder: 'Số điện thoại' },
        desc: { ...inputModel, isRequired: false, placeholder: 'Nhập mô tả chi tiết của bạn' },
    });

    const [infoData, setInfoData] = useState({});
    const [isExpand, setIsExpand] = useState(displayFull || false);

    useEffect(() => {
        if (isEmpty(store.consultantFilters) && isEmpty(infoData)) return;
        const newInfo = { ...store.consultantFilters };
        Object.keys(newInfo).forEach(slug => {
            newInfo[slug] = { ...newInfo[slug], selecteds: null, error: '' };
        });
        setInfoData({ ...newInfo });
    }, [store.consultantFilters]);
    // If login, auto set
    useEffect(() => {
        const currentUser = store.profileCommunity;
        if (isEmpty(currentUser)) return;
        setState({
            ...state,
            name: { ...state.name, value: currentUser.display_name },
            email: { ...state.email, value: currentUser.email },
            phone: { ...state.phone, value: currentUser.phone_number },
        });
    }, [store.profileCommunity]);

    // Create validate function after click submit button
    const validate = () => {
        const newState = { ...state };
        const newInfoData = { ...infoData };
        let isValid = true;
        Object.keys(newState).forEach(key => {
            if (newState[key].isRequired && isEmpty(newState[key].value) && newState[key].slug !== "khu_vuc_hourse") {
                newState[key].error = 'Vui lòng nhập thông tin';
                isValid = false;
            } else {
                newState[key].error = '';
            }
        });
        //Sử dụng hàm có sẵn trong inputModel.js để validate email và số điện thoại
        if (newState['email'].value && !validateEmail(newState['email'].value)) {
            newState['email'].error = 'Email không đúng định dạng';
            isValid = false;
        }

        if (newState['phone'].value && !validatePhone(newState['phone'].value)) {
            newState['phone'].error = 'Số điện thoại không đúng định dạng';
            isValid = false;
        }

        Object.keys(newInfoData).forEach(key => {
            if (newInfoData[key].isRequired && isEmpty(newInfoData[key].selecteds) && newInfoData[key].slug !== "khu_vuc_hourse") {
                newInfoData[key].error = 'Vui lòng nhập thông tin';
                isValid = false;
            } else {
                newInfoData[key].error = '';
            }
        });
        setState(newState);
        setInfoData({ ...newInfoData });
        return isValid;
    };

    const onSubmit = async () => {
        // If validate fail, return
        if (!validate()) {
            insider_click_gui_tu_van({ pro_id: `${chuyenGia.id}`, pro_name: chuyenGia.display_name, success: false });
            return;
        };

        const terms = Object.values(infoData).filter(item => {
            return item.slug !== 'dich-vu' && item.slug !== 'hang-muc' && item.slug !== 'khu-vuc' && item.slug !== 'construction-time';
        });
        let term_ids = [];
        terms.forEach(item => {
            if (!item) return;
            if (isArray(item.selecteds)) {
                term_ids = term_ids.concat(item.selecteds.map(x => x.value));
                return;
            };
            if (item.selecteds) term_ids.push(item.selecteds.value);
        });

        const body = {
            "post_id": article?.id,
            "professional_id": chuyenGia?.id == 18 ? null : chuyenGia?.id,
            "name": state.name.value,
            "email": state.email.value,
            "phone": state.phone.value,
            "desc": state.desc.value,
            "term_ids": term_ids,
            "construction_time": infoData['construction-time'].selecteds?.value || null,
            "service": infoData['dich-vu'].selecteds?.value || null,
            "service_item": infoData['hang-muc'].selecteds?.value || null,
            "province_id" : infoData['khu-vuc'].selecteds?.value || null,
            "source": source || null,
        };

        try {
            const res = await http(`community/consultant`, {}, {
                body: body,
                method: 'POST',
            }, 'v2');
            if (res.success) {
                toast.info('Yêu cầu đã được gửi thành công!');
                insider_click_gui_tu_van({ pro_id: `${chuyenGia.id}`, pro_name: chuyenGia.display_name, success: true });
                resetForm();
            } else {
                insider_click_gui_tu_van({ pro_id: `${chuyenGia.id}`, pro_name: chuyenGia.display_name, success: false });
                toast.error(res.errors || 'Đã có lỗi xảy ra!');
            }
        } catch (e) {
            console.log(e);
            toast.error('Đã có lỗi xảy ra!');
        }
    };

    const onChangeSelect = (slug) => {
        const func = selecteds => {
            const newInfoData = { ...infoData };
            newInfoData[slug] = {
                ...newInfoData[slug],
                selecteds: selecteds,
                error: ''
            };
            setInfoData(newInfoData);
        };
        return func;
    };

    const onChangeText = (slug) => {
        const func = e => {
            const newInfoData = { ...state };
            newInfoData[slug] = {
                ...newInfoData[slug],
                value: e.target.value,
                error: ''
            };
            setState(newInfoData);
        };
        return func;
    };

    style = {
        ...style,
        height: 'auto',
        borderRadius: 12,
        border: '1px #EEEEEE solid',
        zIndex: 10,
        transition: 'height 0.3s ease-in-out'
    };

    const resetForm = () => {
        setState({
            ...state,
            desc: { ...state.desc, ...inputModel },
        });
        const newInfo = { ...infoData };
        Object.keys(newInfo).forEach(slug => {
            newInfo[slug] = { ...newInfo[slug], selecteds: null, error: '' };
        });
        setInfoData(newInfo);
    };

    const clickForm = () => {
        setIsExpand(true);

        const key = `consultant-${window.location.href}`;
        if (isArray(getCookie(key))) {
            setCookie(key, true, (1 / 24) / 6);
            insider_click_form_tu_van({ pro_id: `${chuyenGia.id}`, pro_name: chuyenGia.display_name });
        }
    };
    console.log(infoData);
    return store.consultantFilters.receive_advice
        ? <div className="form-dang-ky-tu-van" id="dang-ky-tu-van"
            style={style}
            onFocus={() => setIsExpand(true)}
            onClick={clickForm}
        >
            {!hideHeader && <div className="header-form" style={{ display: 'inline-flex', width: '100%', borderTopLeftRadius: 12, borderTopRightRadius: 12 }}>
                <div style={{ width: 'calc(100% - 64px)' }}>
                    <p className="title">
                        {chuyenGia.id == 18 ? 'Đăng ký kết nối Thiết kế - thi công với ' : 'Đăng ký tư vấn với '} <a href={`/thanh-vien/u/${chuyenGia.id}?t=tong-quan`} className="no-color"><u>{chuyenGia.display_name}</u></a>
                    </p>
                    <p className="mt-2" style={{}}>
                        Chúng tôi sẽ giúp bạn có một không gian sống phù hợp nhất với yêu cầu
                    </p>
                </div>
                <img src="/images/icon-nha.png" height={64} />
            </div>}
            <div className="body-form" style={{ height: 'auto', transition: 'height 0.3s ease-in-out' }}>
                <div className="form-group">
                    <label className="form-title">
                        <UserOctagon size={20} color="#AAA" className="mr-2" />
                        Thông tin liên hệ <span className="c-red">*</span>
                    </label>
                    <div className="row">
                        <div className="col-md-6 col-12 pr-md-1">
                            <MyInput data={state.name} onChange={onChangeText('name')} />
                        </div>
                        <div className="col-md-6 col-12 pl-lg-1">
                            <MyInput data={state.phone} onChange={onChangeText('phone')} />
                        </div>
                        <div className="col-12">
                            <MyInput data={state.email} onChange={onChangeText('email')} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-6 pr-md-1">
                        <div className="form-group">
                            <label className="form-title">
                                <MaskRight size="20" color="#AAA" className="mr-2" />
                                Dịch vụ
                            </label>
                            <MySelect data={infoData['dich-vu']} onChange={onChangeSelect('dich-vu')} />
                        </div>
                    </div>
                    <div className="col-12 col-md-6 pl-lg-1">
                        <div className="form-group">
                            <label className="form-title">
                                <MainComponent size="20" color="#AAA" className="mr-2" />
                                Hạng mục
                            </label>
                            <MySelect data={infoData['hang-muc']} onChange={onChangeSelect('hang-muc')} />
                        </div>
                    </div>
                </div>
                {isExpand && <div className="form-group">
                    <label className="form-title">
                        <House2 size="20" color="#AAA" className="mr-2" />
                        Yêu cầu của bạn
                    </label>
                    <div className="row">
                        <div className="col-md-6 col-12 pr-md-1">
                            <MySelect data={infoData['loai-nha']} onChange={onChangeSelect('loai-nha')} />
                        </div>
                        <div className="col-md-6 col-12 pl-lg-1">
                            <MySelect data={infoData['dien-tich']} onChange={onChangeSelect('dien-tich')} />
                        </div>
                        <div className="col-md-6 col-12 pr-md-1">
                            <MySelect data={infoData['ngan-sach']} onChange={onChangeSelect('ngan-sach')} />
                        </div>
                        <div className="col-md-6 col-12 pl-lg-1">
                            <MySelect data={infoData['khu-vuc']} onChange={onChangeSelect('khu-vuc')} />
                        </div>
                        <div className="col-md-6 col-12 pr-md-1">
                            <MySelect isMultiple={true} data={infoData['phong-cach']} onChange={onChangeSelect('phong-cach')} />
                        </div>
                        <div className="col-md-6 col-12 pl-lg-1">
                            <MySelectInput data={infoData['construction-time']}
                                label={""}
                                textDefault={"Khác"}
                                isMultiple={false}
                                onChange={onChangeSelect('construction-time')}/>
                        </div>
                    </div>
                </div>}
                {isExpand && <div className="form-group">
                    <label className="form-title">
                        <Edit2 size="20" color="#AAA" className="mr-2" />
                        Mô tả
                    </label>
                    <textarea
                        value={state.desc.value}
                        className={"form-control" + (state.desc.error ? " border-error" : "")}
                        onChange={onChangeText('desc')}
                        placeholder="Nhập mô tả chi tiết của bạn"
                        rows="4"
                        style={{ height: 'auto' }}
                    />
                    {state.desc.error && <span className="c-red font-size-13 ml-2">{state.desc.error}</span>}
                </div>}
            </div>
            <div className="text-center pb-3">
                <button className="btn btn--blue pl-3 pr-3" onClick={onSubmit}>Gửi yêu cầu</button>
            </div>
        </div>
        : <></>;
};

export function MySelect({ onChange, data = {} }) {
    const customStyles = {
        control: (base) => ({
            ...base,
            color: 'black',
            cursor: 'pointer',
            paddingLeft: '5px',
            borderRadius: '8px',
            minHeight: '44px',
            marginTop: '12px',
            borderColor: data.error ? '#f00' : '#DDDDDD',
        }),
        valueContainer: (base) => ({
            ...base,
            // maxHeight: '64px',
            // 'overflow-y': 'scroll'
        }),
        option: (provided, state) => {
            let color = {
                backgroundColor: provided.backgroundColor,
                color: provided.color,
            };

            if (state.isFocused) {
                color = {
                    backgroundColor: '#E7FCFD',
                    color: '#34BEEF',
                };
            }

            if (!state.isFocused && state.isSelected) {
                color = {
                    backgroundColor: '#fff',
                    color: '#444',
                };
            }

            return {
                ...provided,
                ...color,
                cursor: 'pointer',
            };
        },
        placeholder: (defaultStyles) => ({
            ...defaultStyles,
        }),
        indicatorSeparator: (base) => ({
            ...base,
            display: 'none'
        })
    };

    return <><Select
        components={{ DropdownIndicator: customDropdownIndicator, Option: CustomOption }}
        isMulti={data.isMultiple}
        closeMenuOnSelect={!data.isMultiple}
        options={data.options || []}
        styles={customStyles}
        hideSelectedOptions={false}
        placeholder={<p>{data.name} {data.isRequired && <span className="c-red">*</span>}</p>}
        onChange={onChange}
        value={data.selecteds}
    />
    {data.error && <span className="c-red font-size-13 ml-2">{data.error}</span>}
    </>;
};

export function MyInput({ data, onChange, className = '', style = {}, ...props }) {
    data = data || {};
    return (
        <>
            <input
                type=""
                style={style}
                className={
                    'form-control' +
                    (data.error ? ' border-error' : '') +
                    ' ' +
                    className
                }
                value={data.value}
                placeholder={data.placeholder}
                onChange={onChange}
                {...props}
            />
            {data.error && (
                <span className="c-red font-size-13 ml-2">{data.error}</span>
            )}
        </>
    );
}

export function MyRadio({ onChange, data = {}, style = {} }) {
    data = data || {};
    style = {
        direction: 'vertical',
        size: 12,
        wrap: false,
        ...style,
    };

    return <>
        {data.error && <div className="c-red font-size-13">{data.error}</div>}
        <Radio.Group onChange={onChange} value={data.value}>
            <Space direction={style.direction} wrap={style.wrap} size={style.size}>
                {(data.options || []).map((item, index) => <Radio key={index} value={item.value}>{item.label}</Radio>)}
            </Space>
        </Radio.Group>
    </>;
}

const customDropdownIndicator = (props) => {
    const isSelected = !isEmpty(props.getValue());
    return (
        <components.DropdownIndicator {...props}>
            {isSelected && <TickCircle
                color="#34beef"
                variant="Bold"
            />}
        </components.DropdownIndicator>
    );
};

const CustomOption = ({ children, ...props }) => {
    return (
        <components.Option {...props}>
            {children}
            {
                props.isSelected && <TickCircle
                    color="#34beef"
                    variant="Bold"
                    size={20}
                    style={{ float: 'right' }}
                />
            }
        </components.Option>
    );
};
