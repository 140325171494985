import { isMobile } from "react-device-detect";
import {FloatButton} from "antd";
import {useEffect, useState} from "react";
import {useCart} from "../../framework/store";

export default function ButtonContactHappynest({isDisplayFloatButton = false}) {
    const [store] = useCart();
    const bottom = isMobile ? '112px' : '85px';
    const bottomShow = isMobile ? '112px' : '85px';
    const bottomShowIcon = isMobile ? '110px' : '83px';
    const [showContact, setShowContact] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);
    const handleContact = () => {
        setShowTooltip(false);
        setShowContact(!showContact);
    };
    const handleMouseEnter = () => {
        setShowTooltip(!showContact);
    };
    const handleMouseLeave = () => {
        setShowTooltip(false);
    };
    // useEffect(() => {
    //     setTimeout(() => {
    //         setShowTooltip(true);
    //     }, 5000);
    // }, []);

    useEffect(() => {
        if (showTooltip) {
            setTimeout(() => {
                setShowTooltip(false);
            }, 5000);
        }
    }, [showTooltip]);
    return (
        store.consultantFilters.receive_advice
            ? <>{isDisplayFloatButton && <>
                <div className="position-fixed"
                    style={{
                        display: showTooltip ? 'block' : 'none',
                        zIndex: 1030,
                        right: '70px',
                        bottom: bottom,
                        borderRadius: '67px',
                        backgroundColor: '#F3F3F3',
                        color: '#000',
                        fontSize: '12px',
                        padding: '12px',
                        boxShadow: '0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05)',
                    }}>
                    <div className="position-relative">
                    Liên hệ với Happynest
                    </div>
                </div>
            </>}
            {showContact && <>
                <div className="position-fixed"
                    style={{
                        display: 'block',
                        zIndex: 1030,
                        right: '75px',
                        bottom: bottomShowIcon,
                        borderRadius: '10px',
                        backgroundColor: 'transparent',
                        fontSize: '12px',
                        padding: '3px 5px',
                    }}>
                    <div className="position-relative" style={{
                        display: 'flex', gap: '5px', alignItems: "center"
                    }}>
                        <a href="tel:0934680636"
                            className={"ant-float-btn-default-zalo"}
                            style={{
                                width: '45px', height: '45px', position: "initial",marginRight:"10px"
                            }}>
                            <img className="contact-phone" src="/icons/buttonPhone.png"/>
                        </a>
                        <a href="https://zalo.me/0934680636"
                            className={"ant-float-btn-default-zalo"}
                            target={"_blank"}
                            style={{
                                width: '45px', height: '45px', position: "initial"
                            }} rel="noreferrer">
                            <img className="contact-zalo" src="/icons/buttonContactZalo.png"/>
                        </a>
                        <img src="/icons/ArrowUp.png" className={"arrow-up-zalo"}/>
                    </div>
                </div>
            </>}
            {isDisplayFloatButton && <>
                <FloatButton
                    type="primary"
                    className={"float-btn-default-zalo"}
                    style={{
                        backgroundColor: '#34BEEF',
                        width: '46px',
                        height: '46px',
                        right: '24px',
                        bottom: bottomShow
                    }}
                    onMouseOver={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onClick={handleContact}
                    icon={false}
                    description={<img className="contact-zalo-phone" src="/icons/buttonContact.png"/>}
                />
            </>}
            </>:<></>);
};