/* eslint-disable @next/next/no-img-element */
import Link from 'next/link';
import {useEffect, useState} from 'react';
import http from 'utilities/http';
import {isEmpty, trim} from "lodash";
import {useCart} from "framework/store";
import {getCookie} from 'framework/libs/cookie';
import {TOKEN_CONGDONG} from 'framework/store/constants';
import {useRouter} from 'next/router';
import {Loader} from "components/loader";
import { formatString } from 'utilities/helper';
import SeeMore from "components/seeMore";

function searchComponent({state, setState, isFull =  false}) {
    const perPage = 5;
    const router = useRouter();
    const [stateStore, actions] = useCart();

    const [searchValue, setSearchValue] = useState('');
    const [searchDisplay, setSearchDisplay] = useState([]);
    const [popularSearch, setPopularSearch] = useState(null);

    const [loading,setLoading] = useState(false);
    const [seeMore, setSeeMore] = useState([perPage, 0]);
    const [currentPage, setCurrentPage] = useState(1);
    const fetchHistory = async () => {
        if (!isEmpty(getCookie(TOKEN_CONGDONG))) {
            setLoading(true);
            const res = await Promise.all([
                http(`community/keyword/history?per_page=${perPage}`, {}, {token: 'Bearer ' + getCookie(TOKEN_CONGDONG)}, 'v2'),
                http('/rest/default/V1/popular-search-list')
            ]);
            setSeeMore([perPage , res[0]?.meta?.pagination?.total]);
            setSearchDisplay(res[0]?.data || []);
            setPopularSearch(res[1]);
            setLoading(false);
        }
        else {
            setLoading(true);
            const res = await http('/rest/default/V1/popular-search-list');
            setSearchDisplay(res);
            setPopularSearch(res);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (state.showSearch) fetchHistory();
    }, [state.showSearch]);

    useEffect(() => {
        if (isEmpty(stateStore.search)) return;
        transitToSearchScreen(stateStore.search);
    }, [stateStore.search]);

    const transitToSearchScreen = (keyword) => {
        setState({showSearch: false});
        if (isEmpty(keyword)) {
            router.push(`/shop`);
            return;
        }
        if (router.pathname.includes("chuyen-gia") || (router.pathname.includes("tim-kiem") && router.query?.t == 'chuyen-gia')) {
            router.push(`/tim-kiem?t=chuyen-gia&keyword=${keyword}`);
            return;
        }
        if (router.pathname.includes("shop") || (router.pathname.includes("tim-kiem") && router.query?.t == 'san-pham')) {
            router.push(`/tim-kiem?t=san-pham&keyword=${keyword}`);
            return;
        }
        if ((router.pathname.includes("tim-kiem") && router.query?.t)) {
            router.push(`/tim-kiem?t=${router.query?.t}&keyword=${keyword}`);
            return;
        }
        router.push(`/tim-kiem?keyword=${keyword}`);
    };
    useEffect(() => {
        if (currentPage > 1) {
            const fetchMoreHistory = async () => {
                setLoading(true);
                const result = await http(`community/keyword/history?per_page=${perPage}&page=${currentPage}`, {},
                    { token: 'Bearer ' + getCookie(TOKEN_CONGDONG) }, 'v2');
                result.code === 200 ? setSearchDisplay( searchDisplay.concat(result?.data)) : "";
                setLoading(false);
            };
            fetchMoreHistory();
        }
    }, [currentPage]);

    return (
        <>
            {/* <Loader loading={loading} /> */}
            <div className={`search ${isFull?'search-full-with':''}`}>
                <div className="search_main" data-toggle="dropdown" onClick={() => setState({showSearch: true})}>
                    <button className="btn">
                        <img src="/images/icon-search.svg" alt=""/>
                    </button>
                    <input className="form-control" onChange={(e) => setSearchValue(e.target.value)} onKeyUp={(e) => {
                        if (e.key === 'Enter' || e.keyCode === 13) {
                            transitToSearchScreen(searchValue);
                        }
                    }} value={searchValue} type="text" placeholder="Tìm kiếm"/>
                </div>
                <div className={`dropdown-menu dropdown-menu_search ${state.showSearch ? 'd-block' : 'd-none'}`}>
                    <div className="search__scroll-max">
                        <div className="head-search">
                            <div className="head-search-wrap">
                                <button className="head-search-btn">
                                    <img src="/images/icon-search.svg" alt=""/></button>
                                <input className="head-search-text" type="" name="" placeholder="Nội thất, ngoại thất"
                                    onChange={(e) => setSearchValue(e.target.value)} onKeyUp={(e) => {
                                        if (e.key === 'Enter' || e.keyCode === 13) {
                                            transitToSearchScreen(searchValue);
                                        }
                                    }} value={searchValue}/>
                            </div>
                            <span className="filter-close" onClick={() => {
                                setState({showSearch: false});
                            }}>Huỷ</span>
                        </div>
                        <div className="search-suggest">
                            <ul className="suggest-list">
                                {
                                    searchDisplay.map((item, index) => {
                                        return (
                                            <li key={index} style={{ cursor: 'pointer',  }}
                                                onClick={() => transitToSearchScreen(item.keyword || (item.query_text))}>
                                                {item.keyword || (item.query_text)}
                                            </li>
                                        );
                                    })
                                }
                            </ul>
                            {/* <SeeMore page={seeMore} currentPage={currentPage} setCurrentPage={setCurrentPage} /> */}
                            {
                                searchDisplay && seeMore[0] * currentPage < seeMore[1] &&
                                <div className="more" style={{ borderTop: 'none', marginTop: '-12px' }}>
                                    <button className="btn btn-more-goi-y"
                                        style={{
                                            width: "100%",
                                            position: 'unset',
                                            border: 'none',
                                            color: '#34beef'
                                        }}
                                        onClick={() => setCurrentPage(currentPage + 1)}
                                    >Xem thêm</button>
                                </div>
                            }
                        </div>
                        
                        { popularSearch &&
                            <div className="search-popula">
                                <div className="search-head">
                                    <h3 className="search-title">Tìm kiếm phổ biến</h3>
                                </div>
                                <ul className="search-popula-list">
                                    {popularSearch?.map((x, i) => <li key={i} onClick={() => transitToSearchScreen(x?.query_text)}>
                                        <div className="thumb">
                                            <img src={x?.image} align="" alt=""/>
                                        </div>
                                        <p className="text">{x?.query_text}</p>
                                    </li>
                                    )}
                                </ul>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className="searchTb dropdown">
                <button className="btn" onClick={() => setState({showSearch: true})} data-toggle="dropdown"><img src="/images/icon-search.svg" alt=""/></button>
                {/* <div className="dropdown-menu dropdown-menu-right">
                    <div className="search-wrap">
                        <input className="form-control" type="text" placeholder="Tìm kiếm"/>
                        <button className="btn"><img src="/images/icon-search.svg" alt=""/></button>
                    </div>
                </div> */}
            </div>
        </>
    );
}

export default (searchComponent);
