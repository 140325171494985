import jsCookie from "js-cookie";

export function getCookie(key) {
    let result = [];
    if (key) {
        const localData = jsCookie.get(key);
        if (localData && localData.length > 0) {
            result = JSON.parse(localData);
        }
    }

    return result;
}

export function setCookie(key, value, expireTime=null) {
    if(expireTime) {
        jsCookie.set(key, JSON.stringify(value), {expires: expireTime});
        return;
    }
    jsCookie.set(key, JSON.stringify(value));
}

export function removeCookie(key){
    jsCookie.remove(key);
}