import { Image } from "react-bootstrap";
import Link from "next/link";
import { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import http from "utilities/http";
import { useRouter } from "next/router";
import { isEmpty, isObject, set } from "lodash";
import { Col, Row, Space, Radio, Modal as ModalAntd } from "antd";
import { inputModel, selectModel, validateEmail, validatePhone } from "models/inputModel";
import { MyInput, MySelect, MyRadio } from "components/don-tu-van/formDangKyTuVan";
import { useCart } from "framework/store";
import { replaceAll } from "utilities/helper";
import { getCookie } from "framework/libs/cookie";
import { TOKEN_CONGDONG } from "framework/store/constants";

const Header = ({}) => {
    const router = useRouter();
    const [stateStore, actions] = useCart();
    const path = router.asPath;
    const [state, setState] = useState({
        showMbMenu: false,
    });

    useEffect(() => {
        if (state.showMbMenu) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "unset";
        }
    }, [state.showMbMenu]);

    const link = {
        gioithieu: "/lam-nha/gioi-thieu",
        uudai: "/lam-nha/uu-dai",
        camnang: "/lam-nha/cam-nang-lam-nha",
        tintuc: "/lam-nha/tin-tuc",
    };

    const [events, setEvents] = useState([]);
    useEffect(() => {
        const fetch = async () => {
            const response = await http('community/build-hourse/setting', null, {}, 'v2');
            if (response.code === 200) {
                const events = (response.terms?.data || []).sort((a, b) => b.name - a.name);
                setEvents(events);
                actions.set('lamNhaEvents', events);
                actions.set('lamNhaSetting', response);
            }
        };
        fetch();
    }, []);

    const selectedYear = events.find((item) => path.split('/')[2] == item.name);
    useEffect(() => {
        const fetch = async () => {
            if (isEmpty(events)) return;
            const thisYear = new Date().getFullYear();
            const thisYearEvent = events.find((item) => item.name == thisYear);
            if (!thisYearEvent) return;
            const res = await http(`community/build-hourse?category_id=${thisYearEvent.id}`, {}, {}, 'v2');
            if (res?.code == 200) {
                actions.set('lamNha', res);
            }
        };
        fetch();
    }, [events]);

    const onOffShowMbMenu = () => {
        setState({ showMbMenu: !state.showMbMenu });
    };

    useEffect(() => {
        const fetch = async () => {
            if(isEmpty(getCookie(TOKEN_CONGDONG))) return;
            const res = await http('user', {}, { token: 'Bearer ' + getCookie(TOKEN_CONGDONG) });
            actions.setProfileCommunity(res);
        };
        fetch();
    }, []);

    return (
        <>
            <div className="header-lamnha">
                <div className="wrap">
                    <div className="header-lamnha-main">
                        <div className="logo">
                            <Link href="/">
                                <div className="header-lamnha-img">
                                    <Image
                                        layout={"fill"}
                                        src={"/images/lam-nha/EventLogo.png"}
                                        align=""
                                        alt={"title"}
                                    />
                                </div>
                            </Link>
                        </div>

                        <div className="menu-lamnha-right-main">
                            <div className="menu-lamnha-link cursor-pointer lamnha-link position-relative">
                                Năm {selectedYear?.name || events[0]?.name || '2023'}
                                <span className="ic-arrow-lamnha">
                                    <Image
                                        width={16}
                                        height={16}
                                        align=""
                                        alt={"title"}
                                        src={
                                            "/images/lam-nha/icon-arrow-black.svg"
                                        }
                                    />
                                </span>
                                <div className="lamnha-dropdown-head">
                                    <div className="lamnha-dropdown-head-main">
                                        {events.map((item, index) => {
                                            return <a href={`/lam-nha/${item.name}`} className="" key={index}>
                                                <div className="menu-lamnha-link cursor-pointer lamnha-link">
                                                    Năm {item.name}
                                                </div>
                                            </a>;
                                        })
                                        }
                                    </div>
                                </div>
                            </div>
                            <Link href={link.gioithieu} className="">
                                <div className="menu-lamnha-link cursor-pointer lamnha-link">
                                    Giới thiệu
                                </div>
                            </Link>
                            <Link href={link.uudai} className="">
                                <div className="menu-lamnha-link cursor-pointer lamnha-link">
                                    Ưu đãi
                                </div>
                            </Link>
                            <Link href={link.camnang} className="">
                                <div className="menu-lamnha-link cursor-pointer lamnha-link">
                                    Cẩm nang làm nhà
                                </div>
                            </Link>
                            <Link href={link.tintuc} className="">
                                <div className="menu-lamnha-link cursor-pointer lamnha-link">
                                    Tin tức
                                </div>
                            </Link>
                        </div>
                        <button className="btn-book" style={{ }}>
                            Đặt lịch tư vấn
                            <span className="ic-arrow-lamnha">
                                <Image
                                    width={16}
                                    height={16}
                                    align=""
                                    alt={"title"}
                                    src={
                                        "/images/lam-nha/icon-arrow-book.svg"
                                    }
                                />
                            </span>
                            <div className="lamnha-dropdown-head">
                                <div className="lamnha-dropdown-head-main">
                                    <FormDangKyTuVanLamNha type={1}>
                                        <div className="menu-lamnha-link text-left cursor-pointer lamnha-link">
                                            Dành cho chủ nhà
                                        </div>
                                    </FormDangKyTuVanLamNha>
                                    <FormDangKyTuVanLamNha type={2}>
                                        <div className="menu-lamnha-link text-left cursor-pointer lamnha-link">
                                            Dành cho đối tác
                                        </div>
                                    </FormDangKyTuVanLamNha>
                                </div>
                            </div>
                        </button>

                        <div
                            className={`burger-menu ${
                                state.showMbMenu ? "active" : ""
                            }`}
                            onClick={onOffShowMbMenu}
                        >
                            <div />
                            <div />
                            <div />
                        </div>

                        <div
                            className={`menu-mb ${
                                state.showMbMenu ? "active" : ""
                            }`}
                        >
                            <div className="menu-mb-wrap">
                                <Link href="#" className="">
                                    <div className="menu-lamnha-link cursor-pointer lamnha-link position-relative">
                                        Năm
                                        <div className="menu-lamnha-sub">
                                            {
                                                events.map((item, index) => {
                                                    return <Link href={`/lam-nha/${item.name}`} className="" key={index}>
                                                        <div className="menu-lamnha-link cursor-pointer lamnha-link" onClick={onOffShowMbMenu}>
                                                            {item.name}
                                                        </div>
                                                    </Link>;
                                                })
                                            }
                                        </div>
                                    </div>
                                </Link>
                                <Link href={link.gioithieu} className="">
                                    <div className="menu-lamnha-link cursor-pointer lamnha-link" onClick={onOffShowMbMenu}>
                                        Giới thiệu
                                    </div>
                                </Link>
                                <Link href={link.uudai} className="">
                                    <div className="menu-lamnha-link cursor-pointer lamnha-link" onClick={onOffShowMbMenu}>
                                        Ưu đãi
                                    </div>
                                </Link>
                                <Link href={link.camnang} className="">
                                    <div className="menu-lamnha-link cursor-pointer lamnha-link" onClick={onOffShowMbMenu}>
                                        Cẩm nang làm nhà
                                    </div>
                                </Link>
                                <Link href={link.tintuc} className="">
                                    <div className="menu-lamnha-link cursor-pointer lamnha-link" onClick={onOffShowMbMenu}>
                                        Tin tức
                                    </div>
                                </Link>
                            </div>
                            <div className="menu-mb-bottom position-absolute" style={{ bottom: 74 }}>
                                <div className="menu-mb-bottom-wrap">
                                    <p>
                                        Bạn muốn đặt lịch tư vấn 1:1 tại sự
                                        kiện? Hãy điền vào form dưới đây để
                                        Happynest hỗ trợ bạn nhé!
                                    </p>
                                    <Space.Compact className="w-100" size="middle">
                                        <FormDangKyTuVanLamNha type={1}>
                                            <button className="btn btn--blueWhite pt-2 pb-2" style={{ fontSize: 16, width: 'calc(100% - 5px)' }}>
                                                Dành cho chủ nhà
                                            </button>
                                        </FormDangKyTuVanLamNha>

                                        <FormDangKyTuVanLamNha type={2}>
                                            <button className="btn btn--blue pt-2 pb-2" style={{ fontSize: 16, width: 'calc(100% - 5px)' }}>
                                                Dành cho đối tác
                                            </button>
                                        </FormDangKyTuVanLamNha>
                                    </Space.Compact>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Header;

const FormDangKyTuVanLamNha = ({type, children}) => {
    const [stateStore, actions] = useCart();
    const [state, setState] = useState({
        modal: false,
        modalShow: "",
        name: { ...inputModel, isRequired: true, placeholder: 'Họ và tên *' },
        email: { ...inputModel, isRequired: true, placeholder: 'Email *' },
        phone: { ...inputModel, isRequired: true, placeholder: 'Số điện thoại *' },
        gender: { ...selectModel, options: [{ label: 'Nam', value: '1' }, { label: 'Nữ', value: '2' }], isRequired: false, name: 'Giới tính' },
        events: { ...selectModel, options: [], isRequired: true},
        timeSlot: { ...selectModel, options: [], isRequired: true},
        need: {
            ...selectModel,
            isRequired: false,
            options: type === 1
                ? [
                    { label: 'Nhận tư vấn thiết kế, thi công với KTS', value: '1' },
                    { label: 'Nhận tư vấn mua sắm combo ưu đãi với Happynest', value: '2' },
                    { label: 'Hỗ trợ khác', value: '4' },
                ]
                : [
                    { label: 'Nhận tư vấn hoạt động hợp tác với Happnest', value: '3' },
                    { label: 'Hỗ trợ khác', value: '4' },
                ],
        },
        needDetail: { ...inputModel, isRequired: false, placeholder: 'Nhập yêu cầu của bạn tại đây' },
    });

    const handleHide = () => { setState({ ...state, modal: false }); };

    const onChangeSelect = (slug) => {
        const func = selecteds => {
            const newInfoData = { ...state };
            newInfoData[slug] = {
                ...newInfoData[slug],
                selecteds: selecteds,
                error: ''
            };
            setState(newInfoData);
        };
        return func;
    };

    const onChangeText = (slug) => {
        const func = e => {
            const newInfoData = { ...state };
            newInfoData[slug] = {
                ...newInfoData[slug],
                value: e.target.value,
                error: ''
            };
            setState(newInfoData);
        };
        return func;
    };

    useEffect(() => {
        let eventsTimeAndLocation = [];
        (stateStore.lamNha?.events || []).forEach(item => {
            eventsTimeAndLocation = eventsTimeAndLocation.concat(item.events.data.map(event => {
                return {
                    label: replaceAll(event.time_format.date, '.', '/') + ' - ' + event.address,
                    value: event.id,
                    start: event.time_format.start,
                    end: event.time_format.end,
                };
            }));
        });
        setState({
            ...state,
            events: {
                ...state.events,
                options: eventsTimeAndLocation,
            }
        });
    }, [stateStore.lamNha]);

    useEffect(() => {
        const event = state.events.options.find(x => x.value === state.events.value);
        if (!event) return;
        const timeSlot = getTimeSlot(event.start, event.end).map(item => {
            return {
                label: item,
                value: item,
            };
        });
        setState({
            ...state,
            timeSlot: {
                ...state.timeSlot,
                options: timeSlot,
            }
        });
    }, [state.events.value]);

    useEffect(() => {
        const currentUser = stateStore.profileCommunity;
        if (isEmpty(currentUser)) return;
        setState({
            ...state,
            name: { ...state.name, value: currentUser.display_name },
            email: { ...state.email, value: currentUser.email },
            phone: { ...state.phone, value: currentUser.phone_number },
        });
    }, [stateStore.profileCommunity]);

    const validate = () => {
        const newState = { ...state };
        let isValid = true;
        Object.keys(newState).forEach(key => {
            if(!isObject(newState[key])) return;
            if (newState[key].isRequired && isEmpty(newState[key].value.toString())) {
                newState[key].error = 'Vui lòng nhập thông tin';
                isValid = false;
            } else {
                newState[key].error = '';
            }
        });

        //Sử dụng hàm có sẵn trong inputModel.js để validate email và số điện thoại
        if (newState['email'].value && !validateEmail(newState['email'].value)) {
            newState['email'].error = 'Email không đúng định dạng';
            isValid = false;
        }

        if (newState['phone'].value && !validatePhone(newState['phone'].value)) {
            newState['phone'].error = 'Số điện thoại không đúng định dạng';
            isValid = false;
        }

        setState(newState);
        return isValid;
    };

    const onSubmit = async () => {
        // If validate fail, return
        if (!validate()) {
            return;
        };

        const body = {
            "type": 2,
            "type_customter": type,
            "fullname": state.name.value,
            "email": state.email.value,
            "phone_number": state.phone.value,
            "gender": state.gender.value,
            // "role": state.desc.value,
            "post_id" : state.events.value,
            "question": '',
            "time_slot": state.timeSlot.value,
            "need": state.need.value,
            "need_other": state.need.value == 4 ? state.needDetail.value : null,
        };

        try {
            const res = await http(`community/build-hourse`, {}, {
                body: body,
                method: 'POST',
            }, 'v2');
            if (res.success) {
                ModalAntd.success({
                    title: 'Thành công',
                    content: 'Cảm ơn bạn đã đăng ký tham gia sự kiện!',
                });
            } else {
                throw new Error();
            }
        } catch (e) {
            ModalAntd.error({
                title: 'Đã xảy ra lỗi',
                content: 'Vui lòng thử lại!',
            });
        }
    };

    return <>
        <div className="w-100" onClick={() =>
            setState({
                ...state,
                modal: true,
                modalShow: type == 1 ? "modal-chu-nha" : "modal-doi-tac",
            })
        }>
            {children}
        </div>

        <Modal
            show={state.modal}
            onHide={handleHide}
            className="modal_lamnha_index"
        >
            <div className="custom-back-modal" onClick={handleHide}>
                <Image
                    src={"..//images/lam-nha/ic-back-nav.svg"}
                    alt="backmodal"
                />
            </div>
            <Modal.Header closeButton style={{ padding: 24 }}>
                <div className="lamnha-modal-title text-center">
                    <h5 className="modal-title">Đặt lịch tư vấn</h5>
                    <p className="">
                        {
                            state.modalShow === "modal-chu-nha" ? 'Dành cho chủ nhà' : 'Dành cho đối tác'
                        }
                    </p>
                </div>
            </Modal.Header>
            <Modal.Body style={{ padding: 24, paddingTop: 0 }}>
                <div className="form-dang-ky-tu-van">
                    <div className="register-lamnha-form">
                        <Space direction="vertical" size={24}>
                            <div>
                                <label className="form-title font-weight-500">
                                    Thông tin liên hệ
                                </label>
                                <Row gutter={8}>
                                    <Col xs={24} lg={12}>
                                        <MyInput data={state.name} onChange={onChangeText('name')} />
                                    </Col>
                                    <Col xs={24} lg={12}>
                                        <MyInput data={state.phone} onChange={onChangeText('phone')} />
                                    </Col>
                                    <Col xs={24} lg={12}>
                                        <MySelect data={state.gender} onChange={onChangeSelect('gender')} />
                                    </Col>
                                    <Col xs={24} lg={12}>
                                        <MyInput data={state.email} onChange={onChangeText('email')} />
                                    </Col>
                                </Row>
                            </div>

                            <div>
                                <Row gutter={8}>
                                    <Col xs={24} lg={12}>
                                        <label className="form-title font-weight-500" style={{ marginBottom: 12 }}>
                                            Bạn đăng ký đặt lịch tư vấn tại sự kiện nào <span className="c-red">*</span>
                                        </label>
                                        <div>
                                            <MyRadio data={state.events} onChange={onChangeText('events')} style={{ size: 12 }} />
                                        </div>
                                    </Col>
                                    <Col xs={24} lg={12}>
                                        <div className="sp-only" style={{ height: 24 }}></div>
                                        <label className="form-title font-weight-500" style={{ marginBottom: 12 }}>
                                        Lựa chọn khung giờ đặt lịch trước để không phải chờ đợi <span className="c-red">*</span>
                                        </label>
                                        <div>
                                            {state.timeSlot.error && <div className="c-red font-size-13">{state.timeSlot.error}</div>}
                                            <Radio.Group onChange={onChangeText('timeSlot')} value={state.timeSlot.value} style={{ width: '100%' }}>
                                                <Row className="w-100">
                                                    {(state.timeSlot.options || []).map((item, index) => {
                                                        return <Col key={index} xs={24} lg={12} style={{ marginBottom: 12 }}>
                                                            <Radio value={item.value}>{item.label}</Radio>
                                                        </Col>;
                                                    })}
                                                </Row>
                                            </Radio.Group>
                                        </div>
                                    </Col>
                                </Row>
                            </div>

                            <div>
                                <label className="form-title font-weight-500" style={{ marginBottom: 12 }}>
                                    Nhu cầu nhận tư vấn 1:1 của bạn tại sự kiện?
                                </label>
                                <div>
                                    <Radio.Group onChange={onChangeText('need')} value={state.need.value} style={{ width: '100%' }}>
                                        <Row className="w-100">
                                            <Col xs={24} lg={12}>
                                                {(state.need.options || []).map((item, index) => {
                                                    if (item.value == 4) return null;
                                                    return <Radio key={index} value={item.value} style={{ marginBottom: 12 }}>{item.label}</Radio>;
                                                })}
                                            </Col>
                                            <Col xs={24} lg={12}>
                                                <Radio value={4}>Hỗ trợ Khác</Radio>
                                                {
                                                    state.need.value == 4 && <MyInput data={state.needDetail} onChange={onChangeText('needDetail')} />
                                                }
                                            </Col>
                                        </Row>
                                    </Radio.Group>
                                </div>
                            </div>
                        </Space>

                        <div className="item">
                            <p className="mb-0 text-center">
                                <button className="btn btn--blue" onClick={() => onSubmit()}>
                                    Đặt lịch
                                </button>
                            </p>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    </>;
};

const getTimeSlot = (start, end) => {
    // From start (hh:mm) to end(hh:mm), every 1 hour, return an Array for time slot
    const startTime = start.split(':');
    const endTime = end.split(':');
    const startHour = parseInt(startTime[0]);
    const endHour = parseInt(endTime[0]);
    const startMin = parseInt(startTime[1]);
    const endMin = parseInt(endTime[1]);
    let timeSlot = [];
    for (let i = startHour; i <= endHour; i++) {
        if (startMin < 30) {
            timeSlot.push(`${i}:00 - ${i + 1}:00`);
        } else {
            timeSlot.push(`${i}:30 - ${i + 1}:30`);
        }
    }
    return timeSlot;
};
