import FormDangKyTuVan from "components/don-tu-van/formDangKyTuVan";
import { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { CloseCircleFilled } from '@ant-design/icons';
import { useRouter } from "next/router";
import { insider_click_button_tu_van } from "utilities/insider-events";
import { useCart } from "framework/store";
import { Badge, Button, FloatButton, Popover } from "antd";
import { isMobile } from "react-device-detect";
import { getCookie, setCookie } from "framework/libs/cookie";
import { TOKEN_CONGDONG } from "framework/store/constants";
import { isEmpty } from "lodash";

export default function ButtonModalDonTuVan({ chuyenGia, article, isDisplayFloatButton = false, isDisplayFullButton = true, source, text = "Đăng ký tư vấn", hasIcon = true,isDisplayButtonTuVan=false }) {
    chuyenGia = chuyenGia || { id: 18, display_name: 'Happynest' };
    const [store] = useCart();
    const router = useRouter();
    const [showModalTuVan, setShowModalTuVan] = useState(false);
    const handleShowModalTuVan = () => {
        insider_click_button_tu_van({ pro_id: `${chuyenGia.id}`, pro_name: chuyenGia.display_name });
        setShowModalTuVan(true);
    };
    const handleMouseEnter = () => {
        setShowTooltip(true);
    };
    const handleMouseLeave = () => {
        setShowTooltip(false);
    };
    const handleCloseModalTuVan = () => {
        setShowModalTuVan(false);
    };

    useEffect(() => {
        if (!router.query?.open_advise_form) return;
        handleShowModalTuVan();
    }, [router.query?.open_advise_form]);

    const token = getCookie(TOKEN_CONGDONG);
    const key = `${!isEmpty(token) ? token : ''}-tu-van`;
    const cookieToken = getCookie(key);
    const [showTooltip, setShowTooltip] = useState(false);
    useEffect(() => {
        if (cookieToken !== true) {
            setTimeout(() => {
                setShowTooltip(true);
            }, 5000);
        }
    }, []);

    useEffect(() => {
        if (showTooltip) {
            setTimeout(() => {
                setShowTooltip(false);
            }, 5000);
        }
    }, [showTooltip]);

    return (
        store.consultantFilters.receive_advice
            ? <>
                {isDisplayFullButton && !isDisplayButtonTuVan && <Button type="primary" className="color-primary bg-bright border-none w-100" style={{
                    paddingLeft : "5px",
                    backgroundColor : '#69D6FF',
                    color : '#fff',
                    marginTop: '5px',
                    height: '48px',
                    display: 'flex',
                    alignItems: 'center',
                }} onClick={handleShowModalTuVan}>
                    {hasIcon && <span className="mr-1"><img  className="register-design" style={{
                        width : "30px"
                    }} src="/images/form-tu-van/icon-dang-ky-tu-van.png" /></span>}{text}
                </Button>}
                {isDisplayButtonTuVan && <Button type="primary" className="color-primary bg-bright border-none w-100" style={{
                    paddingLeft : "5px",
                    backgroundColor : '#69D6FF',
                    color : '#fff',
                    marginTop: '0',
                    height: '32px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }} onClick={handleShowModalTuVan}>
                    {hasIcon && <span className="mr-1"><img  className="register-design" style={{
                        width : "30px"
                    }} src="/images/form-tu-van/icon-dang-ky-tu-van.png" /></span>}{text}
                </Button>}
                {isDisplayFloatButton && <>
                    <div className="position-fixed"
                        style={{
                            display: showTooltip ? 'block' : 'none',
                            zIndex: 1030,
                            right: '70px',
                            bottom: isMobile ? '225px' : '210px',
                            borderRadius: '67px',
                            backgroundColor: '#F3F3F3',
                            color: '#000',
                            fontSize: '12px',
                            padding: '12px',
                            boxShadow: '0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05)',
                        }}>
                        <div className="position-relative">
                            Kết nối thiết kế, thi công
                            {/*<button className="position-absolute"*/}
                            {/*    style={{ top: '-15px', right: '-12px', backgroundColor: '#DDD', borderRadius: '50%', width: '16px', height: '16px', lineHeight: '16px', fontSize: '10px', fontWeight: '600' }}*/}
                            {/*    onClick={() => { setCookie(key, true, 1 / 24); setShowTooltip(false); }}*/}
                            {/*>*/}
                            {/*    X*/}
                            {/*</button>*/}
                        </div>
                    </div>
                    <FloatButton
                        type="primary"
                        style={{ backgroundColor: '#34BEEF', width: '44px', height: '44px', right: '24px', bottom: isMobile ? '225px' : '210px'}}
                        icon={false}
                        onMouseOver={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        onClick={handleShowModalTuVan}
                        badge={{ count: "", style: { top: '-12px', right: '-14px' } }}
                        description={<img className="register-design" src="/images/form-tu-van/icon-dang-ky-tu-van.png" />}
                    />
                </>}
                <Modal show={showModalTuVan} onHide={handleCloseModalTuVan} size="lg">
                    <button className="position-absolute" style={{ top: '6px', right: '9px', zIndex: 11 }} onClick={() => setShowModalTuVan(false)}>
                        <CloseCircleFilled style={{ fontSize: '20px', color: 'white' }} />
                    </button>
                    <FormDangKyTuVan article={article} chuyenGia={chuyenGia} displayFull={true} source={source} />
                </Modal>
            </>
            : <></>
    );
};
