import Select, {components} from "react-select";
import CreatableSelect from 'react-select/creatable';
import http from 'utilities/http';
import {useEffect, useState} from "react";
import {toast} from "react-toastify";
import {isArray, isEmpty} from "lodash";
import {Edit2, House2, MainComponent, MaskRight, TickCircle, UserOctagon} from 'iconsax-react';
import {useCart} from "framework/store";
import {inputModel, validateEmail, validatePhone} from "models/inputModel";
import {insider_click_form_tu_van, insider_click_gui_tu_van} from "utilities/insider-events";
import {getCookie, setCookie} from "framework/libs/cookie";
import {Radio, Space} from "antd";

export default function FormMuaSamHoanThienNha({
    style,
    chuyenGia,
    article,
    hideHeader = false,
    displayFull = false,
    source
}) {
    chuyenGia = chuyenGia || {id: 18, display_name: 'Happynest'};
    const [store] = useCart();
    const [state, setState] = useState({
        name: {...inputModel, isRequired: true, placeholder: 'Họ và tên'},
        email: {...inputModel, isRequired: true, placeholder: 'Email'},
        phone: {...inputModel, isRequired: true, placeholder: 'Số điện thoại'},
        desc: {...inputModel, isRequired: false, placeholder: 'Nhập mô tả chi tiết của bạn'},
    });

    const [infoData, setInfoData] = useState({});
    const [isExpand, setIsExpand] = useState(displayFull || false);


    useEffect(() => {
        if (isEmpty(store.consultantFilters) && isEmpty(infoData)) return;
        const newInfo = {...store.consultantFilters};
        Object.keys(newInfo).forEach(slug => {
            newInfo[slug] = {...newInfo[slug], selecteds: null, error: ''};
        });
        setInfoData({...newInfo});
    }, [store.consultantFilters]);
    // If login, auto set
    useEffect(() => {
        const currentUser = store.profileCommunity;
        if (isEmpty(currentUser)) return;
        setState({
            ...state,
            name: {...state.name, value: currentUser.display_name},
            email: {...state.email, value: currentUser.email},
            phone: {...state.phone, value: currentUser.phone_number},
        });
    }, [store.profileCommunity]);

    // Create validate function after click submit button
    const validate = () => {
        const newState = {...state};
        const newInfoData = {...infoData};
        let isValid = true;
        Object.keys(newState).forEach(key => {
            if (newState[key].isRequired && isEmpty(newState[key].value)) {
                newState[key].error = 'Vui lòng nhập thông tin';
                isValid = false;
            } else {
                newState[key].error = '';
            }
        });

        //Sử dụng hàm có sẵn trong inputModel.js để validate email và số điện thoại
        if (newState['email'].value && !validateEmail(newState['email'].value)) {
            newState['email'].error = 'Email không đúng định dạng';
            isValid = false;
        }

        if (newState['phone'].value && !validatePhone(newState['phone'].value)) {
            newState['phone'].error = 'Số điện thoại không đúng định dạng';
            isValid = false;
        }
        Object.keys(newInfoData).forEach(key => {
            const required = ["khu_vuc_hourse", "giai_doan_nha", "phong", "ngan_sach_hourse", "product"];
            if (newInfoData[key].isRequired && isEmpty(newInfoData[key].selecteds) && required.includes(key)) {
                newInfoData[key].error = 'Vui lòng nhập thông tin';
                isValid = false;
            } else {
                newInfoData[key].error = '';
            }
        });

        setState(newState);
        setInfoData({...newInfoData});
        return isValid;
    };

    const onSubmit = async () => {
        // If validate fail, return
        console.log(validate());
        if (!validate()) {
            insider_click_gui_tu_van({pro_id: `${chuyenGia.id}`, pro_name: chuyenGia.display_name, success: false});
            return;
        }
        const body = {
            "name": state.name.value,
            "email": state.email.value,
            "phone": state.phone.value,
            "desc": state.desc.value,
            "khu_vuc": MyGetValueMultiSelect(infoData['khu_vuc_hourse']?.selecteds) || null,
            "ngan_sach": MyGetValueMultiSelect(infoData['ngan_sach_hourse']?.selecteds) || null,
            "phong": MyGetValueMultiSelect(infoData['phong']?.selecteds) || null,
            "giai_doan_nha": MyGetValueMultiSelect(infoData['giai_doan_nha']?.selecteds) || null,
            "product": MyGetValueMultiSelect(infoData['product']?.selecteds) || null,
        };

        try {
            const res = await http(`complete-hourse`, {}, {
                body: body, method: 'POST',
            }, 'v2');
            if (res.success) {
                toast.info('Yêu cầu đã được gửi thành công!');
                insider_click_gui_tu_van({pro_id: `${chuyenGia.id}`, pro_name: chuyenGia.display_name, success: true});
                resetForm();
            } else {
                insider_click_gui_tu_van({pro_id: `${chuyenGia.id}`, pro_name: chuyenGia.display_name, success: false});
                toast.error(res.errors || 'Đã có lỗi xảy ra!');
            }
        } catch (e) {
            console.log(e);
            toast.error('Đã có lỗi xảy ra!');
        }
    };
    let isShowProduct = true;
    const onChangeSelect = (slug) => {
        const func = selecteds => {

            const newInfoData = {...infoData};
            newInfoData[slug] = {
                ...newInfoData[slug], selecteds: selecteds, error: ''
            };
            if (slug === "phong") {
                isShowProduct = false;
                let selectProducts = [];
                if (selecteds.length > 0) {
                    selecteds.forEach((item) => {
                        if (typeof item.products != "undefined" && item.products.length > 0) {

                            item.products = item.products.map((x) => ({
                                value: x.id,
                                label: x.id,
                                id: x.id,
                                text: x.id,
                                options: (isArray(x.options) && x.options.length > 0) ? x.options : null,
                                //isDisabled: true
                            }));
                            selectProducts = [...selectProducts, ...item.products];
                        }
                    });
                }
                isShowProduct = true;

                newInfoData["product"].options = selectProducts;
            }
            setInfoData(newInfoData);
        };
        return func;
    };

    const onChangeText = (slug) => {
        return e => {
            const newInfoData = {...state};
            newInfoData[slug] = {
                ...newInfoData[slug], value: e.target.value, error: ''
            };
            setState(newInfoData);
        };
    };

    style = {
        ...style,
        height: 'auto',
        borderRadius: 12,
        border: '1px #EEEEEE solid',
        zIndex: 10,
        transition: 'height 0.3s ease-in-out'
    };

    const resetForm = () => {
        setState({
            ...state, desc: {...state.desc, ...inputModel},
        });
        const newInfo = {...infoData};
        Object.keys(newInfo).forEach(slug => {
            newInfo[slug] = {...newInfo[slug], selecteds: null, error: ''};
        });
        setInfoData(newInfo);
    };

    const clickForm = () => {
        setIsExpand(true);

        const key = `consultant-${window.location.href}`;
        if (isArray(getCookie(key))) {
            setCookie(key, true, (1 / 24) / 6);
            insider_click_form_tu_van({pro_id: `${chuyenGia.id}`, pro_name: chuyenGia.display_name});
        }
    };
    return store.consultantFilters.receive_advice ?
        <div className="form-dang-ky-tu-van" id="form-dang-ky-hoan-thien-nha"
            style={style}
            onFocus={() => setIsExpand(true)}
            onClick={clickForm}
        >
            {!hideHeader && <div className="header-form" style={{
                display: 'inline-flex', width: '100%', borderTopLeftRadius: 12, borderTopRightRadius: 12
            }}>
                <div style={{width: 'calc(100% - 64px)'}}>
                    <p className="title">
                        {chuyenGia.id == 18 ? 'Gửi yêu cầu mua sắm hoàn thiện nhà ' : 'Gửi yêu cầu mua sắm hoàn thiện nhà '}
                        <a href={`/thanh-vien/u/${chuyenGia.id}?t=tong-quan`}
                            className="no-color"><u>{chuyenGia.display_name}</u></a>
                    </p>
                    <p className="mt-2" style={{}}>
                        Chúng tôi sẽ giúp bạn có một không gian sống phù hợp nhất với yêu cầu
                    </p>
                </div>
                <img src="/images/icon-nha.png" height={64}/>
            </div>}
            <div className="body-form" style={{height: 'auto', transition: 'height 0.3s ease-in-out'}}>
                <div className="form-group">
                    <label className="form-title">
                        <UserOctagon size={20} color="#AAA" className="mr-2"/>
                        Thông tin liên hệ <span className="c-red">*</span>
                    </label>
                    <div className="row">
                        <div className="col-md-6 col-12 pr-md-1">
                            <MyInput data={state.name} onChange={onChangeText('name')}/>
                        </div>
                        <div className="col-md-6 col-12 pl-lg-1">
                            <MyInput data={state.phone} onChange={onChangeText('phone')}/>
                        </div>
                        <div className="col-12">
                            <MyInput data={state.email} onChange={onChangeText('email')}/>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-6 pr-md-1">
                        <div className="form-group">
                            <label className="form-title">
                                <MaskRight size="20" color="#AAA" className="mr-2"/>
                                Khu vực
                            </label>
                            <MySelectInput data={infoData['khu_vuc_hourse']}
                                label={"Khu vực"}
                                isMultiple={false}
                                onChange={onChangeSelect('khu_vuc_hourse')}/>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 pl-lg-1">
                        <div className="form-group">
                            <label className="form-title">
                                <MainComponent size="20" color="#AAA" className="mr-2"/>
                                Giai đoạn nhà
                            </label>
                            <MySelectInput data={infoData['giai_doan_nha']}
                                label={"Giai đoạn nhà"}
                                isMultiple={false}
                                onChange={onChangeSelect('giai_doan_nha')}/>
                        </div>
                    </div>
                </div>
                {isExpand && <div className="form-group">
                    <label className="form-title">
                        <House2 size="20" color="#AAA" className="mr-2"/>
                        Nhu cầu mua sắm
                    </label>
                    <div className="row">
                        <div className="col-md-6 col-12 pr-md-1">
                            <MySelectInput data={infoData['phong']}
                                label={"Nhu cầu mua sắm"}
                                onChange={onChangeSelect('phong')}/>
                        </div>
                        <div className="col-md-6 col-12 pl-lg-1">
                            <MySelectInput data={infoData['ngan_sach_hourse']}
                                label={"Ngân sách"}
                                isMultiple={false}
                                onChange={onChangeSelect('ngan_sach_hourse')}/>
                        </div>
                        {isShowProduct &&
                            <div className="col-md-6 col-12 pr-md-1">
                                <MySelectInput data={infoData['product']}
                                    label={"Sản phẩm"}
                                    onChange={onChangeSelect('product')}/>
                            </div>
                        }
                    </div>
                </div>}
                {isExpand && <div className="form-group">
                    <label className="form-title">
                        <Edit2 size="20" color="#AAA" className="mr-2"/>
                        Mô tả
                    </label>
                    <textarea
                        value={state.desc.value}
                        className={"form-control" + (state.desc.error ? " border-error" : "")}
                        onChange={onChangeText('desc')}
                        placeholder="Nhập mô tả chi tiết của bạn"
                        rows="4"
                        style={{height: 'auto'}}
                    />
                    {state.desc.error && <span className="c-red font-size-13 ml-2">{state.desc.error}</span>}
                </div>}
            </div>
            <div className="text-center pb-3">
                <button className="btn btn--blue pl-3 pr-3" onClick={onSubmit}>Gửi yêu cầu</button>
            </div>
        </div> : <></>;
};
export const MyGetValueMultiSelect = (items) => {

    if (isArray(items) && items.length > 0) {
        return items.map((item) => {
            return item.value;
        });
    }
    if (!isArray(items)) {
        return [items.value];
    }
    return [];
};

export function MyInput({data, onChange, className = '', style = {}, ...props}) {
    data = data || {};
    return (<>
        <input
            type=""
            style={style}
            className={'form-control' + (data.error ? ' border-error' : '') + ' ' + className}
            value={data.value}
            placeholder={data.placeholder}
            onChange={onChange}
            {...props}
        />
        {data.error && (<span className="c-red font-size-13 ml-2">{data.error}</span>)}
    </>);
}

export function MyRadio({onChange, data = {}, style = {}}) {
    data = data || {};
    style = {
        direction: 'vertical', size: 12, wrap: false, ...style,
    };

    return <>
        {data.error && <div className="c-red font-size-13">{data.error}</div>}
        <Radio.Group onChange={onChange} value={data.value}>
            <Space direction={style.direction} wrap={style.wrap} size={style.size}>
                {(data.options || []).map((item, index) => <Radio key={index} value={item.value}>{item.label}</Radio>)}
            </Space>
        </Radio.Group>
    </>;
}

export function MySelectInput({onChange, label = "", isMultiple = "true", data = {}, textDefault = "Thêm"}) {
    const customStyles = {
        control: (base) => ({
            ...base,
            color: 'black',
            cursor: 'pointer',
            paddingLeft: '5px',
            borderRadius: '8px',
            minHeight: '44px',
            marginTop: '12px',
            borderColor: data.error ? '#f00' : '#DDDDDD',
        }), valueContainer: (base) => ({
            ...base, // maxHeight: '64px',
            // 'overflow-y': 'scroll'
        }), option: (provided, state) => {
            let color = {
                backgroundColor: provided.backgroundColor, color: provided.color,
            };

            if (state.isFocused) {
                color = {
                    backgroundColor: '#E7FCFD', color: '#34BEEF',
                };
            }

            if (!state.isFocused && state.isSelected) {
                color = {
                    backgroundColor: '#fff', color: '#444',
                };
            }

            return {
                ...provided, ...color, cursor: 'pointer',
            };
        }, placeholder: (defaultStyles) => ({
            ...defaultStyles,
        }), indicatorSeparator: (base) => ({
            ...base, display: 'none'
        })
    };
    const groupStyles = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    };
    const groupBadgeStyles = {
        backgroundColor: '#EBECF0',
        borderRadius: '2em',
        color: '#172B4D',
        display: 'inline-block',
        fontSize: 12,
        fontWeight: 'normal',
        lineHeight: '1',
        minWidth: 1,
        padding: '0.16666666666667em 0.5em',
        textAlign: 'center',
    };

    const formatGroupLabel = (data) => (
        <div style={groupStyles}>
            <span>{data.label}</span>
            <span style={groupBadgeStyles}>{data.options.length}</span>
        </div>
    );
    const customFormatCreateLabel = inputValue => `+ ${textDefault} ${label} : ${inputValue}`;
    return <><CreatableSelect
        components={{DropdownIndicator: customDropdownIndicator, Option: CustomOption}}
        isMulti={isMultiple}
        closeMenuOnSelect={!data.isMultiple}
        options={data.options || []}
        styles={customStyles}
        hideSelectedOptions={false}
        formatCreateLabel={customFormatCreateLabel}
        placeholder={<p>{data.name} {data.isRequired && <span className="c-red">*</span>}</p>}
        onChange={onChange}
        value={data.selecteds}
        formatGroupLabel={formatGroupLabel}
    />
    {data.error && <span className="c-red font-size-13 ml-2">{data.error}</span>}
    </>;
};

export function MySelect({onChange, data = {}}) {
    const customStyles = {
        control: (base) => ({
            ...base,
            color: 'black',
            cursor: 'pointer',
            paddingLeft: '5px',
            borderRadius: '8px',
            minHeight: '44px',
            marginTop: '12px',
            borderColor: data.error ? '#f00' : '#DDDDDD',
        }), valueContainer: (base) => ({
            ...base, // maxHeight: '64px',
            // 'overflow-y': 'scroll'
        }), option: (provided, state) => {
            let color = {
                backgroundColor: provided.backgroundColor, color: provided.color,
            };

            if (state.isFocused) {
                color = {
                    backgroundColor: '#E7FCFD', color: '#34BEEF',
                };
            }

            if (!state.isFocused && state.isSelected) {
                color = {
                    backgroundColor: '#fff', color: '#444',
                };
            }

            return {
                ...provided, ...color, cursor: 'pointer',
            };
        }, placeholder: (defaultStyles) => ({
            ...defaultStyles,
        }), indicatorSeparator: (base) => ({
            ...base, display: 'none'
        })
    };

    return <><Select
        components={{DropdownIndicator: customDropdownIndicator, Option: CustomOption}}
        isMulti={true}
        closeMenuOnSelect={!data.isMultiple}
        options={data.options || []}
        styles={customStyles}
        hideSelectedOptions={false}
        placeholder={<p>{data.name} {data.isRequired && <span className="c-red">*</span>}</p>}
        onChange={onChange}
        value={data.selecteds}
    />
    {data.error && <span className="c-red font-size-13 ml-2">{data.error}</span>}
    </>;
};
const customDropdownIndicator = (props) => {
    const isSelected = !isEmpty(props.getValue());
    return (<components.DropdownIndicator {...props}>
        {isSelected && <TickCircle
            color="#34beef"
            variant="Bold"
        />}
    </components.DropdownIndicator>);
};

const CustomOption = ({children, ...props}) => {
    return (<components.Option {...props}>
        {children}
        {props.isSelected && <TickCircle
            color="#34beef"
            variant="Bold"
            size={20}
            style={{float: 'right'}}
        />}
    </components.Option>);
};
