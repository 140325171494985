/* eslint-disable indent */
import { ChatContext } from "context/chatContext";
import Image from "next/image";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import ChatForm from "components/chat/chatForm";
import ChatHeader from "./chatHeader";
import ChatList from "./chatList";
import http from "utilities/http";
import io from "socket.io-client";
import { getCookie, setCookie } from "framework/libs/cookie";
import { TOKEN_CONGDONG, TOKEN_SHOP, TOKEN_CHAT } from "framework/store/constants";
import { API_URL, API_URL_V2 } from "utilities/constants";
import { isEmpty } from 'lodash';
import dayjs from "dayjs";
import isEqual from "lodash/isEqual";
import { MessengerChat } from 'react-messenger-chat-plugin';
import { useRouter } from "next/router";

export const COOKIE_VISITOR_SIGNATURE = 'vlivechat_visitor_id';
export const EVENT_VISITOR_CHAT_INIT = 'visitor chat init';
export const EVENT_VISITOR_CHAT_UPDATE = 'visitor chat update';
export const EVENT_VISITOR_CHAT_START = 'visitor chat start';
export const EVENT_VISITOR_CHAT_NEW = 'visitor chat new';
export const EVENT_VISITOR_CHAT_END = 'visitor chat end';
export const EVENT_VISITOR_SENDS_MESSAGE = 'visitor sends message';
export const EVENT_VISITOR_SENDS_MESSAGE_RESULT = 'visitor sends message result';
export const EVENT_RECEIVE_MESSAGE = 'receive message';
export const EVENT_CHAT_STATUS_CHANGES = 'chat status changes';
export const EVENT_CONNECTED = 'connected';
export const EVENT_DISCONNECTED = 'diconnect';
export const EVENT_TYPING = 'typing';
export const EVENT_USER_DICONNECTED = 'user diconnected';
export const EVENT_USER_LEFT = 'user left';
export const EVENT_OPERATOR_STATUS_CHANGE = 'operator changes status';

const ChatWindow = () => {
    const { isChatHidden, vendor, setIsChatHidden, setVendor } = useContext(ChatContext);
    const [user, setUser] = useState();
    const [chatList, setChatList] = useState([]);
    const [chatMessages, setChatMessages] = useState([]);
    const [vendorLogo, setVendorLogo] = useState();
    const [socket, setSocket] = useState();
    const [visitor, setVisitor] = useState();
    const [chatSessionId, setChatSessionId] = useState();
    const [currentChatId, setCurrentChatId] = useState();
    const [init, setInit] = useState();
    const [unreadCount, setUnreadCount] = useState(0);
    const router = useRouter();

    useEffect(() => {
        (async () => {
            if (isEmpty(getCookie(TOKEN_SHOP))) return;
            const response = await http("/rest/default/V1/live-chat/init", {}, {
                method: 'POST',
                token: "Bearer " + getCookie(TOKEN_SHOP),
                body: {
                    "signature": localStorage.getItem('signature') || '',
                    "vendorId": 0,
                    "pageTitle": '',
                    "url": ''
                }
            });

            if (!response) { return; }
            localStorage.setItem("signature", response.visitor?.signature);
            setInit(response);
            setChatSessionId(response.session_id);
            if (response.visitor) {
                setVisitor(response.visitor);
            }

            // if(response.current_url_id){
            //     this.currentUrlId(response.current_url_id);
            // }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            if (isEmpty(getCookie(TOKEN_SHOP))) return;
            const userResponse = await http('/rest/default/V1/customers/me', {}, { token: 'Bearer ' + getCookie(TOKEN_SHOP) });
            if (!userResponse?.message) {
                setUser(userResponse);
            }
        })();
    }, []);

    const getMessageDetailPro = async (cid) => {
        const responsePro = await http('chat/messages', {}, {
            token: getCookie(TOKEN_CHAT),
            method: 'POST',
            body: {
                "cid": `cid:${cid}`,
                // "page": 1
            }
        }, 'chat');

        if (responsePro.data) {
            setChatMessages(responsePro.data.messages.reverse() ?? []);
            setVendorLogo(responsePro.data.conversation?.target?.avatar?.uri);
            setCurrentChatId(cid);
        }
    };

    const getMessageDetail = async () => {
        if (vendor) {
            setVendorLogo(vendor.vendor_logo_url);
        }
        if (vendor && !vendor.isPro && (chatList).some(x => x.vendor_id == vendor.id)) {
            const response = await http("/rest/default/V1/live-chat/get", {}, {
                method: 'POST',
                body: { visitorEmail: user.email, vendorId: vendor.id }
            });
            if (response) {
                setChatMessages(response.messages ?? []);
                // setVendorLogo(response.vendor_logo_url);

                if (response.messages[response.messages.length - 1]?.chat_id)
                    setCurrentChatId(response.messages[response.messages.length - 1]?.chat_id);
            }
        }
        const proChat = (chatList).find(x => x.vendor_id == vendor.id);
        if (vendor && vendor.isPro && proChat) {
            getMessageDetailPro(proChat.cid);
        }
    };

    //Get message
    useEffect(() => {
        if (!user) return;
        getMessageDetail();
    }, [vendor, user]);

    //Setup socket
    useEffect(() => {
        if (!vendor || !visitor || !chatSessionId) { return; }
        const socket = io(API_URL + ":2020");

        socket.on(EVENT_CONNECTED, (message) => {
            // console.log(EVENT_CONNECTED);
            // console.log(message);
            var params = {
                chats: [],
                vendor_id: vendor.id,
                visitor_id: visitor.visitor_id,
                session_id: chatSessionId,
                current_url_id: 0
            };

            socket.emit(EVENT_VISITOR_CHAT_INIT, params);
        });

        // socket.on(EVENT_DISCONNECTED, (message) => {
        //     console.log(EVENT_DISCONNECTED);
        //     console.log(message);
        // });

        // socket.on(EVENT_VISITOR_CHAT_UPDATE, (message) => {
        //     console.log(EVENT_VISITOR_CHAT_UPDATE);
        //     console.log(message);
        // });

        socket.on(EVENT_VISITOR_CHAT_NEW, (data) => {
            // console.log(EVENT_VISITOR_CHAT_NEW);
            // console.log(data);

            setCurrentChatId(data.chat.chat_id);
        });

        // socket.on(EVENT_VISITOR_SENDS_MESSAGE_RESULT, (message) => {
        //     console.log(EVENT_VISITOR_SENDS_MESSAGE_RESULT);
        //     console.log(message);
        // });

        socket.on(EVENT_RECEIVE_MESSAGE, async (data) => {
            // console.log(EVENT_RECEIVE_MESSAGE);
            // console.log(data);

            if (!data || !data.message || !data.message.message) {
                return;
            }

            if (data.message.message.includes("[img")) {
                const imgUrl = data.message.message.split("[img ").pop().split("]")[0];
                const message = `<div class=\"vlivechat-img-container\"><img src=\"https://sellercenter.happynest.vn/media/ves_vendorslivechat/attachment${imgUrl}\" /></div>`;
                updateChat(message);
            } else if (data.message.message != " joined the chat" && data.message.message != "left the chat") {
                updateChat(data.message.message);
            }
        });

        // socket.on(EVENT_CHAT_STATUS_CHANGES, (message) => {
        //     console.log(EVENT_CHAT_STATUS_CHANGES);
        //     console.log(message);
        // });

        // socket.on(EVENT_TYPING, (message) => {
        //     console.log(EVENT_TYPING);
        //     console.log(message);
        // });

        // socket.on(EVENT_USER_DICONNECTED, (message) => {
        //     console.log(EVENT_USER_DICONNECTED);
        //     console.log(message);
        // });
        setSocket(socket);
    }, [vendor, chatSessionId, visitor, user]);

    const subscribe = async () => {
        if (!user) return;
        let chatListRes = [];
        const response = await http("/rest/default/V1/live-chat/getList", {}, {
            method: 'POST',
            body: { visitorEmail: user.email }
        });
        if (response && !response?.message) {
            chatListRes = chatListRes.concat(Object.values(response));
        }

        if (isEmpty(getCookie(TOKEN_CHAT))) return;
        const responsePro = await http('chat/index', {}, {
            token: getCookie(TOKEN_CHAT),
            method: 'POST',
            body: {
                "keyword": "",
                "page": 1
            }
        }, 'chat');
        if (responsePro?.data) {

            responsePro.data.forEach(data => {
                const shopConcept = {
                    company: data.target?.name,
                    last_message: data.last_message,
                    last_message_date: data.updated_at,
                    vendor_id: data.target?.uuid?.slice(5) || data.target?.id,
                    vendor_logo_url: data.target?.avatar?.uri || null,
                    vendor_name: data.target?.name,
                    isPro: true,
                    cid: data.cid,
                    unread: data.unread,
                };

                if (!isChatHidden && vendor && vendor.isPro && vendor.id == shopConcept.vendor_id && shopConcept.unread != 0) {
                    getMessageDetailPro(shopConcept.cid);
                }

                chatListRes.push(shopConcept);
            });
        };

        setChatList(chatListRes.sort((a, b) => dayjs(b.last_message_date) - dayjs(a.last_message_date)));
        setUnreadCount(chatListRes.filter(x => x.unread != 0 && x.isPro == true).length);
    };

    useEffect(() => {
        if (!user) return;
        subscribe();
        const interval = setInterval(() => subscribe(), 10000);
        return () => {
            clearInterval(interval);
        };
    }, [user]);

    // useEffect(() => {
    //     if (!user) return;
    //     subscribe();
    // }, [chatMessages]);

    useEffect(() => {
        if (!user) return;
        if (isEmpty(chatList)) return;
        if (isEmpty(vendor)) return;
        const pro = chatList.find(x => x.isPro && vendor.id == x.vendor_id && x.unread != 0);
        if (vendor && vendor.isPro && pro) {
            getMessageDetailPro(pro.cid);
        }
        if (vendor && !vendor.isPro) {
            if (!isEmpty(chatMessages) && chatList[0].message != chatMessages[chatMessages.length-1].message && chatList[0].last_message_date != chatMessages[chatMessages.length-1].message_created_at) {
                getMessageDetail();
            }
        }
    }, [chatList]);

    // useEffect(() => {
    //     if (!isChatHidden) setVendor({});
    // }, [isChatHidden]);

    const sendMessage = async (message, images) => {
        if (!vendor.isPro) {
            if (!(chatList).some(x => x.vendor_id == vendor.id)) {
                var data = {
                    vendor_id: vendor.id,
                    signature: visitor.signature,
                    name: user?.firstname,
                    email: user?.email,
                    message,
                    session_id: chatSessionId,
                    current_url_id: 0
                };
                socket.emit(EVENT_VISITOR_CHAT_START, data);
            } else {
                var postData = {
                    signature: localStorage.getItem('signature') || visitor.signature,
                    message: message,
                    chat_id: currentChatId,
                    message_key: Math.round(Math.random() * 100000)
                };
                socket.emit(EVENT_VISITOR_SENDS_MESSAGE, postData);
            }
            setChatMessages(prev => [...prev, { type: "1", message }]);
        } else {
            if (!(chatList).some(x => x.vendor_id == vendor.id)) {
                const responsePro = await http('chat/post', {}, {
                    token: getCookie(TOKEN_CHAT),
                    method: 'POST',
                    body: {
                        "cid": `mid:${vendor.id}`,
                        "message": message,
                    }
                }, 'chat');

                if (responsePro.code == 200) {
                    setChatMessages(prev => [...prev, { type: "out", message }]);
                }
            } else {
                const responsePro = await http('chat/post', {}, {
                    token: getCookie(TOKEN_CHAT),
                    method: 'POST',
                    body: {
                        "cid": `cid:${currentChatId}`,
                        "message": message,
                        "images": images
                    }
                }, 'chat');

                if (responsePro.code == 200 && message) {
                    setChatMessages(prev => [...prev, { type: "out", message }]);
                }

                if (responsePro.code == 200 && !isEmpty(images)) {
                    setChatMessages(prev => [...prev, { type: "out", images: images }]);
                }
            }
        }
    };

    const updateChat = (newMessage) => {
        setChatMessages(prev => [...prev, { type: "2", message: newMessage }]);
    };

    const showChatWindow = () => {
        setIsChatHidden(!isChatHidden);
    };

    const openChat = (chat) => {
        setVendor({
            id: chat.vendor_id,
            vendor_logo_url: chat.vendor_logo_url,
            name: chat.company || chat.vendor_name,
            isPro: chat.isPro,
            cid: chat.cid
        });
    };

    const [keyword, setKeyword] = useState('');
    const isShop = useMemo(() => router.asPath.includes("/shop"), [router.asPath]);
    return (
        <>
            {/*<MessengerChat*/}
            {/*    pageId={isShop ? "102613535541984" : "915522845226873"}*/}
            {/*    appId={isShop ? "323027830104045" : "984476728630070"}*/}
            {/*    themeColor={"#34beef"}*/}
            {/*/>*/}
            <div className="groupChart d-none">
                <div className="btnChat" onClick={showChatWindow}>
                    <img
                        src="/images/icon-chat.svg"
                        width={27}
                        height={27}
                        alt=""
                    />
                    <div className="btnChat-number">
                        {unreadCount > 0 && <span>{unreadCount}</span>}
                    </div>
                </div>
                {!isChatHidden && (
                    <div className="chatContent">
                        <ChatHeader collapseChat={() => setIsChatHidden(true)} find={setKeyword} />
                        <div className="chatMain">
                            <ChatList chats={chatList.filter(x => x.company.toLocaleLowerCase().indexOf(keyword.toLocaleLowerCase()) >= 0)} activeChat={vendor || {}} openChat={openChat} />
                            <ChatForm vendor={vendor} vendorId={vendor ? vendor.id : null} vendorLogo={vendorLogo} vendorName={vendor ? vendor.name : ""} messages={chatMessages} sendMessage={sendMessage} />
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default ChatWindow;
