import "styles/globals.css";
import "public/css/bootstrap-datepicker.min.css";
import "public/css/bootstrap-select.min.css";
import "public/css/bootstrap.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "public/css/styles.css";
import "public/css/form.css";
import "public/css/common.css";
import "public/css/nha-ban-hang-uy-tin.css";
import "public/css/main-page.css";
import "public/css/table-don-tu-van.css";
import "public/css/tree-menu.css";
import "public/css/lam-nha/index.css";
import "public/css/site-mini.css";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import HeaderHome from "components/home/header";
import HeaderHomeLamNha from "components/lam-nha/header";
import HeaderSiteMini from "../components/site-mini/header";
import FooterHome from "components/home/footer";
import FooterSiteMini from "../components/site-mini/footer";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ChatWindow from "components/chat/chatWindow";
import {ChatContextProvider} from "context/chatContext";
import Head from "next/head";
import {
    apiAddProductToCart,
    fetchCartProduct,
    createCart,
} from "framework/api/cart";
import {TOKEN_SHOP} from "framework/store/constants";
import {getCookie} from "framework/libs/cookie";
import {toast} from "react-toastify";
import {isEmpty} from "lodash";
import {useCart} from "framework/store";
import {HomeSchema} from "../schemas/HomeSchema";
import {ConfigProvider, Typography} from "antd";
import ButtonModalDonTuVan from "components/don-tu-van/buttonModalDonTuVan";
import ButtonModalMuaSamHoanThienNha from "components/mua-sam-hoan-thien-nha/buttonModalMuaSamHoanThienNha";
import SliderAd from "components/chuyen-gia/slider";
import router from "next/router";
import ButtonContactHappynest from "components/contact/buttonContactHappynest";

function MyApp({Component, pageProps, router}) {
    const [stateCart, actions] = useCart();
    dayjs.extend(customParseFormat);
    const addToCart = async (sku, quantity) => {
        if (isEmpty(getCookie(TOKEN_SHOP))) {
            toast.info("Bạn cần đăng nhập để thao tác");
            return false;
        }
        try {
            let body = {};
            if (isEmpty(stateCart.cart?.customer)) {
                const createCartAPI = await createCart();
                if (
                    createCartAPI?.message &&
                    createCartAPI?.message.indexOf(
                        "The consumer isn't authorized"
                    ) > -1
                ) {
                    handleLogout();
                    return false;
                }
                const cart = await fetchCartProduct();
                body = {
                    cartItem: {
                        quote_id: !isEmpty(getCookie(TOKEN_SHOP))
                            ? cart.id
                            : getCookie(CART),
                        sku: sku,
                        qty: quantity,
                    },
                };
                actions.cart(cart);
            } else {
                body = {
                    cartItem: {
                        quote_id: !isEmpty(getCookie(TOKEN_SHOP))
                            ? stateCart.cart.id
                            : getCookie(CART),
                        sku: sku,
                        qty: quantity,
                    },
                };
            }
            const apiAdd = await apiAddProductToCart(body);
            if (apiAdd?.message) {
                if (
                    apiAdd.message.indexOf("The consumer isn't authorized") > -1
                ) {
                    handleLogout();
                } else {
                    toast.error(apiAdd.message);
                }
                return false;
            }

            const response = await fetchCartProduct();
            if (
                response?.message &&
                response?.message.indexOf("The consumer isn't authorized") > -1
            ) {
                handleLogout();
                return false;
            }
            actions.cart(response);
            toast.info("Thêm sản phẩm thành công");
            return true;
        } catch (e) {
            toast.error("Đã có lỗi xảy ra.");
            console.log(e);
        }
    };

    if (
        router.route.indexOf("/login") == 0 ||
        router.route.indexOf("/register") == 0 ||
        router.route.indexOf("/forgot-password") == 0 ||
        router.route.indexOf("/tao-bai-viet") == 0
    ) {
        return (
            <>
                <Component {...pageProps} />
                <ToastContainer/>
            </>
        );
    }

    if (typeof window !== "undefined") {
        window.addToCart = addToCart;
        window.insider_object = window.insider_object || {};
        if (isEmpty(stateCart.profileCommunity)) {
            window.insider_object.user = {};
        } else {
            const user = stateCart.profileCommunity || {};
            // window.insider_object.user = {
            //     "uuid": `${user.id || ""}`,
            //     "gender": `${user.gender_id
            //         ? user.gender_id == 2
            //             ? "F"
            //             : "M"
            //         : ""
            //     }`,
            //     "birthday": `${user.birthday || ""}`,
            //     "name": `${user.fullname || ""}`,
            //     "email": `${user.email || ""}`,
            //     "phone_number": `${user.phone_number || ""}`,
            // };
        }
    }

    return (
        <>
            <Head>
                <title>Cộng đồng yêu nhà đẹp Happynest</title>
                <meta
                    property="og:title"
                    content="Cộng đồng yêu nhà đẹp  HAPPYNEST"
                    key="title"
                />
                <meta
                    name="description"
                    key="description"
                    content="Happynest là cộng đồng về nhà ở, được xây dựng trên nền tảng mạng xã hội kết hợp thương mại điện tử (social commerce).
- Cung cấp và truyền cảm hứng về các ý tưởng, giải pháp nhà ở cho người dùng.
- Giới thiệu sản phẩm chính hãng và giá ưu đãi để khách hàng có cơ hội mua sắm, trải nghiệm.
- Kết nối giữa người dùng với nhau, giữa người dùng với các chuyên gia (kiến trúc sư, đơn vị thi công, nhà cung cấp…) nhằm đáp ứng nhu cầu toàn diện về nhà ở trong suốt vòng đời của ngôi nhà."
                ></meta>
                <meta
                    property="og:description"
                    key="og:description"
                    content="Happynest là cộng đồng về nhà ở, được xây dựng trên nền tảng mạng xã hội kết hợp thương mại điện tử (social commerce).
- Cung cấp và truyền cảm hứng về các ý tưởng, giải pháp nhà ở cho người dùng.
- Giới thiệu sản phẩm chính hãng và giá ưu đãi để khách hàng có cơ hội mua sắm, trải nghiệm.
- Kết nối giữa người dùng với nhau, giữa người dùng với các chuyên gia (kiến trúc sư, đơn vị thi công, nhà cung cấp…) nhằm đáp ứng nhu cầu toàn diện về nhà ở trong suốt vòng đời của ngôi nhà."
                ></meta>
                {/* <meta property="og:image" content="https://happynest.vn/v2019/img/logo-seo-happynest.png"></meta> */}
                <meta
                    name="keywords"
                    key="keywords"
                    content="Cộng đồng yêu nhà đẹp: Kho ý tưởng nhà đẹp - Nơi chém gió, chia sẻ kinh nghiệm - Nghe nhà kể chuyện - Kết nối chuyên gia,Kết nối - Truyền cảm hứng"
                ></meta>
                <meta name="robots" content="index,follow"></meta>
                <link rel="shortcut icon" href="/favicon.ico"/>
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1.0, user-scalable=1.0, minimum-scale=1.0, maximum-scale=1.0"
                />
                <meta property="og:type" content="website"/>
                <meta name="author" content="Happynest"/>
                <meta content="vi-VN" itemProp="inLanguage"/>
                <meta
                    content="Happynest.vn"
                    itemProp="sourceOrganization"
                    name="source"
                />
                <meta name="resource-type" content="Document"/>
                <meta name="distribution" content="Global"/>
                <meta name="generator" content="Happynest.vn"/>
                <meta name="source" content="Happynest.vn"/>
                {renderSchema().map((item, index) => {
                    return (
                        <script
                            type="application/ld+json"
                            key={index}
                            dangerouslySetInnerHTML={{
                                __html: JSON.stringify(item),
                            }}
                        ></script>
                    );
                })}
            </Head>
            <ConfigProvider
                theme={{
                    token: {
                        fontFamily: "SF Pro Text",
                        colorPrimary: "#34BEEF",
                    },
                    components: {
                        Radio: {
                            dotSize: 10,
                            radioSize: 20
                        }
                    }
                }}
            >
                <ChatContextProvider>
                    {/*{router.route.indexOf("/lam-nha") == 0 ? (<><div style={{ display: 'none'}}><HeaderHome /></div><HeaderHomeLamNha /></>) : (<HeaderHome />)}*/}
                    {renderHeader(router)}
                    <Component {...pageProps} />
                    {/*{<FooterHome/>}*/}
                    {renderFooter(router)}
                    {!(router.asPath.includes('happynest-story')) && <ChatWindow/>}
                    {<ButtonModalDonTuVan isDisplayFloatButton={true} isDisplayFullButton={false} source={1}/>}
                    {<ButtonModalMuaSamHoanThienNha isDisplayFloatButton={true} isDisplayFullButton={false}
                        source={1}/>}
                    {<ButtonContactHappynest isDisplayFloatButton={true}/>}
                    <ToastContainer/>
                </ChatContextProvider>
            </ConfigProvider>
            {/*{router.route.indexOf("/don-hang-moi") > -1 && (*/}
            {/*    <script*/}
            {/*        dangerouslySetInnerHTML={{*/}
            {/*            __html: `*/}
            {/*        window.insider_object = window.insider_object || {};*/}
            {/*        window.insider_object.page = {*/}
            {/*            "type": "Confirmation"*/}
            {/*        }*/}
            {/*        `,*/}
            {/*        }}*/}
            {/*    ></script>*/}
            {/*)}*/}
            {/*{router.route.indexOf("/don-hang-moi") == -1 && (*/}
            {/*    <script*/}
            {/*        dangerouslySetInnerHTML={{*/}
            {/*            __html: `*/}
            {/*        window.insider_object = window.insider_object || {};*/}
            {/*        ajaxListenerConfirmation = function (callback) {*/}
            {/*            'use strict';*/}
            {/*            var oldOpen = XMLHttpRequest.prototype.open;*/}
            {/*        */}
            {/*            XMLHttpRequest.prototype.open = function (method, url) {*/}
            {/*                this.addEventListener('readystatechange', function () {*/}
            {/*                    if (this.responseType !== 'arraybuffer' && this.responseType !== 'blob' &&*/}
            {/*                        this.readyState === 4 && this.status === 200 && typeof callback === 'function') {*/}
            {/*                        callback(url, this.response, method);*/}
            {/*                    }*/}
            {/*                });*/}
            {/*        */}
            {/*                oldOpen.apply(this, arguments);*/}
            {/*            };*/}
            {/*        };*/}
            {/*        */}
            {/*        ajaxListenerConfirmation(function (url, response, method) {*/}
            {/*            if (method.toLowerCase() === 'post' && url.indexOf('/hit') > -1 &&*/}
            {/*                window.insider_object.page.type === 'Confirmation') {*/}
            {/*                delete (insider_object || {}).page;*/}
            {/*            }*/}
            {/*        });*/}
            {/*        `,*/}
            {/*        }}*/}
            {/*    ></script>*/}
            {/*)}*/}
            {router.route.indexOf("/sites") == -1 && (
                <>
                    <SliderAd positionDesktop="34" pageDesktop="61" positionMobile="35" pageMobile="62"/>
                    <SliderAd positionDesktop="36" pageDesktop="61" positionMobile="37" pageMobile="62"/>
                    <SliderAd positionDesktop="38" pageDesktop="61" positionMobile="39" pageMobile="62"/>
                </>
            )}
        </>
    );
}

export function renderSchema() {
    const schema = new HomeSchema();
    return schema.init();
}
export const renderFooter = (router) => {
    if (router.route.indexOf("/sites") === 0) {
        return <FooterSiteMini/>;
    }
    return <FooterHome/>;
};
export const renderHeader = (router) => {
    if (router.route.indexOf("/lam-nha") == 0) {
        return (<>
            <div style={{display: 'none'}}><HeaderHome/></div>
            <HeaderHomeLamNha/></>);
    }
    if (router.route.indexOf("/sites") === 0) {
        return <HeaderSiteMini/>;
    }
    return <HeaderHome/>;
};

export function reportWebVitals({id, name, label, value}) {
    // Use `window.gtag` if you initialized Google Analytics as this example:
    // https://github.com/vercel/next.js/blob/canary/examples/with-google-analytics/pages/_document.js
    // window.gtag('event', name, {
    //     event_category:
    //         label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
    //     value: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
    //     event_label: id, // id unique to current page load
    //     non_interaction: true, // avoids affecting bounce rate.
    // });

    function sendToGoogleAnalytics({name, delta, id}) {
        // Assumes the global `gtag()` function exists, see:
        // https://developers.google.com/analytics/devguides/collection/gtagjs
        gtag("event", name, {
            event_category: "Web Vitals",
            // The `id` value will be unique to the current page load. When sending
            // multiple values from the same page (e.g. for CLS), Google Analytics can
            // compute a total by grouping on this ID (note: requires `eventLabel` to
            // be a dimension in your report).
            event_label: id,
            // Google Analytics metrics must be integers, so the value is rounded.
            // For CLS the value is first multiplied by 1000 for greater precision
            // (note: increase the multiplier for greater precision if needed).
            value: Math.round(name === "CLS" ? delta * 1000 : delta),
            // Use a non-interaction event to avoid affecting bounce rate.
            non_interaction: true,

            // OPTIONAL: any additional params or debug info here.
            // See: https://web.dev/debug-web-vitals-in-the-field/
            // metric_rating: 'good' | 'ni' | 'poor',
            // debug_info: '...',
            // ...
        });
    }

    // getCLS(sendToGoogleAnalytics);
    // getFID(sendToGoogleAnalytics);
    // getLCP(sendToGoogleAnalytics);
}

export default MyApp;
